import {IOperatorDTO} from '../../../modules/operator/domains/dto/OperatorDTO';
import {IOperatorService} from '../../../modules/operator/domains/interfaces/IOperatorService';
import {Api, LoginApi} from '../bases/Api';
import {IOperatorServerResponse} from './IOperatorServerResponse';
import {parseOperatorsServerResponseToLocalOperator} from './TransformOperatorResponse';

export const OperatorService = (): IOperatorService => {
  const getAll = async (localId: string): Promise<IOperatorDTO[]> => {
    try {
      const response = await Api().get<IOperatorServerResponse[]>(`Operador/BuscarOperadoresPorLocalId/${localId}`, {
        timeout: 30000,
      });

      return parseOperatorsServerResponseToLocalOperator(response.data);
    } catch (error) {
      throw error;
    }
  };

  return {getAll};
};
