import { ConsumeOrderRequest } from '../../../modules/consume/domain/dtos/ConsumeOrderRequest';
import { GetExtractResponse } from '../../../modules/consume/domain/dtos/GetExtractResponse';
import { IGetOrderPadResponse } from '../../../modules/consume/domain/dtos/GetOrderPadResponse';
import { GetSharedOrderPadsResponse } from '../../../modules/consume/domain/dtos/GetSharedOrderPadResponse';
import { IConsumeService } from '../../../modules/consume/domain/interfaces/IConsumeService';
import { Api } from '../bases/Api';
import AnticorruptionConsumeApi from './AnticorruptionConsumeApi';

const ConsumeApi = (): IConsumeService => {
  const api = Api();
  const sendOrder = async (request: ConsumeOrderRequest): Promise<any> => {
    const response = await api.post('/CommandEletronic/ConsumeOrder', request);
    return response;
  };

  const getOrderPad = async (localId: string, tag: string): Promise<IGetOrderPadResponse> => {
    const response = await api.post<IGetOrderPadResponse>('/CommandEletronic/Get', { tag, localId });
    return response.data;
  };

  const getExtract = async (localId: string, orderPadId: string): Promise<GetExtractResponse> => {
    const response = await api.get<GetExtractResponse>(
      `/CommandEletronic/Extract/Account/${orderPadId}/Store/${localId}`,
    );
    return response.data;
  };

  const getSharedOrderPads = async (localId: string): Promise<GetSharedOrderPadsResponse[]> => {
    const response = await AnticorruptionConsumeApi(api).getSharedOrderOldApi(localId);
    return response;
  };
  return { sendOrder, getOrderPad, getExtract, getSharedOrderPads };
};
export default ConsumeApi;
