import { BaseTextFieldProps, TextField, TextFieldProps } from '@mui/material';
import React, { FC, InputHTMLAttributes } from 'react'
import styles from './Input.module.scss'
export interface IInputProps {
    //propertys
    label?: string;
    className?: string;
    variant?: "standard" | "outlined" | "filled";

}
const Input: FC<IInputProps & TextFieldProps> = (props, { variant, className }) => {
    return (
        <TextField
            variant={variant}
            className={`${className} ${styles.textField}`}
            {...props}
            fullWidth={true}
        />

    )
}
export default Input