
import { IOrderComposition } from '../../../../../modules/cart/domain/models/IOrderComposition'
import { IProductComposition } from '../../../../../modules/catalog/domain/models/IProductComposition'
import { IProductCompositionItem } from '../../../../../modules/catalog/domain/models/IProductCompositionItem'
import { CalcTotalsOrderComposition } from './CalcTotalsComposition'


export const ValidateComposition = () => {

    const obterQuantidadeDeItemNoPedido = (_orderCompositions: IOrderComposition[], _productCompositionItem: IProductCompositionItem) => {
        const itemComposition = _orderCompositions.flatMap((_orderComposition) => _orderComposition.compositionItems).find((item) => item.id === _productCompositionItem.id)
        return itemComposition?.quantity ?? 0
    }

    const obterQuaisItemsNaoAtendemOMinimo = (_orderCompositions: IOrderComposition[], _productComposition: IProductComposition) => {
        const itemsCompositionQueNaoAtendemOMinimo = _productComposition.compositionItems.filter((item) => {
            const quantidadeDeComposicaoItemsNoPedido = obterQuantidadeDeItemNoPedido(_orderCompositions, item)
            if (quantidadeDeComposicaoItemsNoPedido - item.min < 0) {
                //item não atendeu o minimo
                return true
            } else {
                return false
            }
        })
        return itemsCompositionQueNaoAtendemOMinimo
    }


    const verificarQuaisComposicoesCujosOsSeusItemsNaoAtendemOMinimo = (_orderCompositions: IOrderComposition[], _productCompositions: IProductComposition[]) => {
        const composicaoCujoOsItemsNaoAtendemOMinimo = _productCompositions.filter(productComposition => !!obterQuaisItemsNaoAtendemOMinimo(_orderCompositions, productComposition).length)
        return composicaoCujoOsItemsNaoAtendemOMinimo
    }

    const verificarQuaisComposicoesNaoAtendemOMinimo = (_orderCompositions: IOrderComposition[], _productCompositions: IProductComposition[]) => {
        const composicaoDeProdutosQuePossuemMinimos = _productCompositions.filter(_productComposition => _productComposition.min);
        const composicoesQueNaoAtendemOminimo = composicaoDeProdutosQuePossuemMinimos.filter(
            (composicaoDeProduto) => {
                // const composicaoQueEstaNoPedido = _orderCompositions.find(orderComposition => orderComposition.id === composicaoDeProduto.id);

                const quantidadeDeItemsMarcados = CalcTotalsOrderComposition(_orderCompositions, composicaoDeProduto);

                if (composicaoDeProduto.min - quantidadeDeItemsMarcados.quantity <= 0) {
                    return false;
                } else {
                    //não atendeu o minimo
                    return true;
                }

            });
        return composicoesQueNaoAtendemOminimo
    }

    const getNotValidateCompositonsByProductComposition = (_orderCompositions: IOrderComposition[], _productCompositions: IProductComposition[]) => {
        const naoAtendemOMinimo = verificarQuaisComposicoesNaoAtendemOMinimo(_orderCompositions, _productCompositions);
        const cujoOsItemsNaoAtendemOMinimo = verificarQuaisComposicoesCujosOsSeusItemsNaoAtendemOMinimo(_orderCompositions, _productCompositions);
        const observacoesDeProdutosQueEstaoEmAlgumaDasDuasListas = _productCompositions.filter((item) => naoAtendemOMinimo.includes(item) || cujoOsItemsNaoAtendemOMinimo.includes(item));
        return observacoesDeProdutosQueEstaoEmAlgumaDasDuasListas;
    }

    return {
        getNotValidateCompositonsByProductComposition,
    }
}



