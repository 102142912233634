import React, { createContext, FC, memo, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import AuthenticationService from '../../../../services/api/authentication/AuthenticationService';
import { Api } from '../../../../services/api/bases/Api';
import { AuthenticationRepository } from '../../../../services/localStorage/authentication/AuthenticationLocalStorage';
// import {useUi} from '../../../ui/presentation/context/UiContext';
import { CheckAuthenticationUseCase } from '../../application/useCases/CheckAuthenticationUseCase';
import { CheckLocalAuthenticationUseCase } from '../../application/useCases/CheckLocalAuthenticationUseCase';
import UseDeviceInfo from './DeviceInfoContext';
import { IAuthentication } from '../interface/IAuthentication';
import { useUi } from '../../../ui/presentation/context/UiContext';

interface IAuthenticationContext {
  authentication?: IAuthentication;
  macAddress?: string;
  saveMacAddress: (mac: string) => void;
  firstOpen: boolean;
  isAuth: boolean;
  hasToken: () => boolean;
}

const __DEV__ = true;

const AuthenticationContext = createContext<IAuthenticationContext>({} as IAuthenticationContext);

export const AuthenticationProvider: FC<{ children: ReactNode }> = memo(({ children }) => {
  const { macAddress, firstOpen, saveMacAddress } = UseDeviceInfo();
  const [authentication, setAuthentication] = useState<IAuthentication>();
  const { showLoading, hideLoading } = useUi();
  const [isAuth, setIsAuth] = useState(false);

  const hasToken = () => {
    const api = Api();
    return !!api.defaults.headers.common.Authorization
  }


  const updateToken = useCallback((_token: string, _authentication: IAuthentication, from?: string) => {
    const api = Api();
    console.log(from, _token)
    api.defaults.headers.common.Authorization = `Bearer ${_token}`;
    setAuthentication(_authentication);
    setIsAuth(true);
  }, []);

  const getLocalAuthentication = useCallback(async () => {
    const authRepository = AuthenticationRepository();
    try {
      showLoading('@authentication-local', 'Autenticando');
      const _authentication = await CheckLocalAuthenticationUseCase(authRepository);
      if (_authentication) {
        __DEV__ && console.log('GET AUTENTICATION FROM LOCAL', _authentication);
      }
    } finally {
      hideLoading('@authentication-local');
    }
  }, [hideLoading, showLoading]);

  const getAuthentication = useCallback(
    async (_macAddress: string) => {
      try {
        // showLoading('@authentication-server', 'Autenticando');
        if (_macAddress) {
          const authService = AuthenticationService();
          const authRepository = AuthenticationRepository();
          const _authentication = await CheckAuthenticationUseCase(authService, authRepository, _macAddress);
          __DEV__ && console.log('GET AUTENTICATION FROM SERVER', _authentication.name);
          updateToken(_authentication.token, _authentication, "API AUTH CB");
        }
      } finally {
        // hideLoading('@authentication-server');
      }
      return () => { };
    },
    [updateToken],
  );

  useEffect(() => {
    if (macAddress) {
      getAuthentication(macAddress);
    }
  }, [getAuthentication, macAddress]);

  useEffect(() => {
    console.log("useEffect getLocal")
    getLocalAuthentication();
  }, [getLocalAuthentication]);


  return (
    <AuthenticationContext.Provider
      value={{
        authentication,
        macAddress,
        saveMacAddress,
        firstOpen,
        isAuth,
        hasToken
      }}>
      <>{children}</>
    </AuthenticationContext.Provider>
  );
});

export const useAuthentication = () => {
  const context = useContext(AuthenticationContext);
  return context;
};