import React, { createContext, FC, ReactNode, useContext } from 'react';
import { ICatalog } from '../domain/models/ICatalog';
import { IProduct } from '../domain/models/IProduct';
import { IProductCategory } from '../domain/models/IProductCategory';
import { IStore } from '../domain/models/IStore';
import UseCatalogContext from './UseCatalogContext';

interface ICatalogContext {
  catalog: ICatalog | undefined;
  loading: boolean;
  getProduct: (productId: string) => IProduct | undefined;
  getCategory: (categoryId: string) => IProductCategory | undefined;
  getStore: (storeId: string) => IStore | undefined;
}

const CatalogContext = createContext<ICatalogContext>({} as ICatalogContext);

export const CatalogProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const catalgoContext = UseCatalogContext();
  return (
    <CatalogContext.Provider value={{ ...catalgoContext }}>
      <>{children}</>
    </CatalogContext.Provider>
  );
};

export const useCatalog = () => {
  const context = useContext(CatalogContext);
  return context;
};
