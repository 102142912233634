import {ICatalogRepository} from '../../domain/interfaces/ICatalogRepository';
import {ICatalog} from '../../domain/models/ICatalog';

const GetLocalCatalogUseCase = async (
  catalogRepository: ICatalogRepository,
): Promise<ICatalog | null> => {
  const response = await catalogRepository.getCatalog();
  return response;
};
export default GetLocalCatalogUseCase;
