import localforage from 'localforage';
import { ILastSyncCatalogResponse } from '../../../modules/catalog/domain/dtos/ILastSyncCatalogResponse';
import { ICatalogRepository } from '../../../modules/catalog/domain/interfaces/ICatalogRepository';
import { ICatalog } from '../../../modules/catalog/domain/models/ICatalog';
const KEY = '@CATALOG'
const CatalogLocalStorage = (): ICatalogRepository => {
  const setCatalog = async (catalog: ICatalog): Promise<ICatalog> => {
    return await localforage.setItem(KEY, catalog)
  };

  const getCatalog = async (): Promise<ICatalog | null> => {
    const response = await localforage.getItem<ICatalog>(KEY)
    return response ? { ...response, fromStorage: true } : null;
  };

  const getLastSyncCatalog = (): Promise<ILastSyncCatalogResponse> => {
    throw 'CatalogRepository function "getLastSyncCatalog" Not implemented';
  };
  const setLastSyncCatalog = (lastSyncCatalog: ILastSyncCatalogResponse): Promise<void> => {
    throw 'CatalogRepository function "setLastSyncCatalog" Not implemented';
  };
  const erase = (): Promise<void> => {
    throw 'CatalogRepository function "erase" Not implemented';
  };

  return {
    setCatalog,
    getCatalog,
    getLastSyncCatalog,
    setLastSyncCatalog,
    erase,
  };
};
export default CatalogLocalStorage;
