
import { Button } from '@mui/material';
import { key } from 'localforage';
import React, { ChangeEvent, FC, FormEvent, forwardRef, useCallback, useMemo, useRef, useState } from 'react'
import Autocomplete from '../../../../components/ui/autocomplete/Autocomplete';
import Input from '../../../../components/ui/input/Input';
import { ISharedOrderPad } from '../../../../modules/consume/domain/models/SharedOrderPad';
import { IOperator } from '../../../../modules/operator/domains/models/Operator';
import NumeriKeyboard from '../numericKeyboard/NumeriKeyboard';
import styles from './OperatorAndOrderPadForm.module.scss'
import ArenaXPImage from '../../../../assets/arenaxp.png'
import FocusAlert from '../focusAlert/FocusAlert';
import { useAuthentication } from '../../../../modules/authentication/presentation/contexts/AuthenticationContext';

export interface operatorAndOrdePageFormValue {

    operatorPassword: string;
    sharedOrderPad?: ISharedOrderPad | null;
    sharedOrderPadNumber?: string;
    orderPadTag: string;

}


export interface SubmitReturnOperatorAndOrdePageFormValue {

    operatorPassword: string;
    sharedOrderPad?: ISharedOrderPad | null;
    operator: IOperator;
    sharedOrderPadNumber?: string;
    orderPadTag: string;

}

export interface OperatorAndOrderPadFormRef {
    setOrderPadTag: () => void;
    setOperatorTag: () => void;
}
export interface IOperatorAndOrderPadFormProps {
    //propertys
    onSubmit: (values: SubmitReturnOperatorAndOrdePageFormValue) => void;
    operators?: IOperator[];
    sharedOrderPads?: ISharedOrderPad[];
    requireSharedOrderPadNumber?: boolean;
    hideSharedOrderPadNumber?: boolean;
    validateSharedOrderPadNumber?: boolean;
    readerAlert?: boolean;
}
const OperatorAndOrderPadForm: FC<IOperatorAndOrderPadFormProps> = forwardRef<OperatorAndOrderPadFormRef, IOperatorAndOrderPadFormProps>(({
    onSubmit,
    operators,
    sharedOrderPads,
    requireSharedOrderPadNumber,
    hideSharedOrderPadNumber = true,
    validateSharedOrderPadNumber
}, ref) => {
    const [focusedInput, setfocusedInput] = useState<'operatorPassword' | 'sharedOrderPadNumber' | 'orderPadTag'>('operatorPassword');
    const [sharedOrderPad, setSharedOrderPad] = useState<ISharedOrderPad>();
    const [values, setValues] = useState<operatorAndOrdePageFormValue>({
        operatorPassword: '',
        sharedOrderPadNumber: '',
        sharedOrderPad: null,
        orderPadTag: '',
    });

    const { authentication } = useAuthentication()

    const [validatedOperator, setValidatedOperator] = useState(false);

    const [error, setError] = useState<{
        operatorPassword?: string;
        sharedOrderPadNumber?: string;
        orderPadTag?: string;
    }>();


    const inputOperatorPassword = useRef<HTMLInputElement>(null)
    const inputSharedOrderPadNumber = useRef<HTMLInputElement>(null)
    const inputOrderPadTag = useRef<HTMLInputElement>(null)

    const getOperatorByPassWord = useCallback((operatorPassword) => {
        const operator = operators?.find(operator => (operator.password === operatorPassword || operator?.tag === operatorPassword))
        return operator
    }, [operators])

    const validateOperator = useCallback(
        () => {
            setError({})
            let response = true;
            if (values.operatorPassword === "") {
                setError(prev => ({ ...prev, operatorPassword: "Insira uma senha válida" }))
                response = false
            } else if (!getOperatorByPassWord(values.operatorPassword)) {
                setError(prev => ({ ...prev, operatorPassword: "Operador não encontrado" }))
                response = false
            }
            setValidatedOperator(response);

            return response
        },
        [getOperatorByPassWord, values.operatorPassword],
    )

    const validatePlace = useCallback(
        () => {
            //notImplemented
            return true
        }, [],
    )


    const validate = useCallback(
        () => {
            let response = true;
            setError({})

            if (values.operatorPassword === "") {
                setError(prev => ({ ...prev, operatorPassword: "Insira uma senha válida" }))
                response = false
            } else if (!getOperatorByPassWord(values.operatorPassword)) {
                setError(prev => ({ ...prev, operatorPassword: "Operador não encontrado" }))
                response = false
            }
            if (values.sharedOrderPadNumber === "" && validateSharedOrderPadNumber) {
                setError(prev => ({ ...prev, sharedOrderPadNumber: "Insira uma mesa válida" }))
                response = false
            }
            if (values.orderPadTag === "") {
                setError(prev => ({ ...prev, orderPadTag: "Insira comanda valida" }))
                response = false
            }
            if (response) {
                setError({})
            }
            return response
        },
        [values.operatorPassword, values.sharedOrderPadNumber, values.orderPadTag, getOperatorByPassWord, validateSharedOrderPadNumber],
    )

    const onSubmitHandle = useCallback(() => {
        if (validate()) {
            inputOperatorPassword.current?.focus();
            const operator = getOperatorByPassWord(values.operatorPassword)
            if (operator) {
                onSubmit({ ...values, operator })
            }

        };
    }, [getOperatorByPassWord, onSubmit, validate, values])

    const setNextFocus = useCallback(
        (ev?: FormEvent<HTMLFormElement>) => {
            ev?.preventDefault();
            if (focusedInput === 'operatorPassword') {
                if (validateOperator()) {
                    if (hideSharedOrderPadNumber) {
                        inputOrderPadTag.current?.focus()
                    } else {
                        inputSharedOrderPadNumber.current?.focus()
                    }
                }
            } else if (focusedInput === 'sharedOrderPadNumber') {
                if (validatePlace()) {
                    inputOrderPadTag.current?.focus()
                }
            } else if (focusedInput === 'orderPadTag') {
                onSubmitHandle()
            }
        },
        [focusedInput, hideSharedOrderPadNumber, onSubmitHandle, validateOperator, validatePlace],
    )


    const onPressKeyHandle = useCallback(
        (value: string) => {
            console.log(values[focusedInput] + value);
            if (value === 'Apagar') {
                setValues(prev => ({ ...prev, [focusedInput]: prev[focusedInput]?.substring(0, (prev[focusedInput]?.length ?? 1) - 1) }))
            } else if (value === 'Enter') {
                setNextFocus();
            } else {
                setValues(prev => ({ ...prev, [focusedInput]: prev[focusedInput] + value }))
            }
            if (focusedInput === 'operatorPassword' && value !== 'Enter') {
                inputOperatorPassword.current?.focus()
            }


        },
        [focusedInput, setNextFocus, values],
    )

    const changeHandle = useCallback((name: string, value: string) => {
        setValues((prev) => ({ ...prev, [name]: value }));
    }, []);


    const image = useMemo(() =>  authentication?.local?.id === '023dc92c-2386-9a5a-e01e-28ae3894c189' ? ArenaXPImage : "/assets/img/LogoMeep.svg", [authentication?.local])

    return (
        <div id={styles.OperatorAndOrderPadForm} >
            <div className={styles.formContainer}>
                <form className={styles.form} onSubmit={setNextFocus}>
                    <div className={styles.logo}>
                        <img src={image} width={250} alt="Logo estabelecimento" />
                    </div>
                    <div
                        className={focusedInput === 'operatorPassword' ? styles.inputFocused : styles.inputContainer}
                    >
                        <Input
                            inputRef={inputOperatorPassword}
                            onChange={(ev) => changeHandle(ev.target.name, ev.target.value)}
                            name='operatorPassword'
                            type='password'
                            variant='outlined'

                            // InputLabelProps={{
                            //     shrink: true,
                            // }}
                            value={values.operatorPassword}
                            onFocus={(ev) => { setfocusedInput('operatorPassword') }}
                            // onBlur={validateOperator}
                            label={'SENHA DO OPERADOR'}
                            autoFocus
                            error={!!error?.operatorPassword}
                            helperText={error?.operatorPassword}
                        ></Input>
                    </div>
                    {!hideSharedOrderPadNumber &&
                        <div
                            className={focusedInput === 'sharedOrderPadNumber' ? styles.inputFocused : styles.inputContainer}
                        >
                            <Input
                                // disabled={!validatedOperator}
                                inputRef={inputSharedOrderPadNumber}
                                onChange={(ev) => changeHandle(ev.target.name, ev.target.value)}
                                name='sharedOrderPadNumber'
                                value={values.sharedOrderPadNumber}
                                onFocus={(ev) => { setfocusedInput('sharedOrderPadNumber') }}
                                label={'MESA'}
                                error={!!error?.sharedOrderPadNumber}
                                helperText={error?.sharedOrderPadNumber}
                                variant='outlined'
                            // InputLabelProps={{
                            //     shrink: true,
                            // }}
                            />
                        </div>
                    }
                    <div
                        className={focusedInput === 'orderPadTag' ? styles.inputFocused : styles.inputContainer}
                    >
                        <Input
                            // disabled={!validatedOperator}
                            inputRef={inputOrderPadTag}
                            onChange={(ev) => changeHandle(ev.target.name, ev.target.value)}
                            name='orderPadTag'
                            value={values.orderPadTag}
                            onFocus={(ev) => { setfocusedInput('orderPadTag') }}
                            label={'COMANDA'}
                            error={!!error?.orderPadTag}
                            helperText={error?.orderPadTag}
                            variant='outlined'
                        // InputLabelProps={{
                        //     shrink: true,
                        // }}
                        />
                    </div>
                    <Button variant='contained' type='submit'>Confirmar</Button>
                </form>

            </div>
            <div className={styles.keyboardContainer}>
                <NumeriKeyboard
                    enterLabel={focusedInput === 'orderPadTag' ? "OK" : "OK"}
                    onPressKey={onPressKeyHandle}
                />
            </div>
            <FocusAlert ></FocusAlert>
        </div>
    )
})
export default OperatorAndOrderPadForm