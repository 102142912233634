import React, { FC, useCallback, useEffect, useMemo } from 'react'
import styles from './SearchInput.module.scss'
import { Icon, IconButton } from '@mui/material'
import KeyboardInput from '../_keyboard/_KeyboardInput'
import { useKeyboard } from '../_keyboard/KeyboardContext'
export interface ISearchInputProps {
    //propertys
    onSubmit: (values: string) => void
    reset?: () => void
}
const SearchInput: FC<ISearchInputProps> = ({ onSubmit, reset }) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const { configureKeyboard } = useKeyboard()
    const [showReset, setShowReset] = React.useState<boolean>(false);

    const onSubmitHandle = useCallback(() => {
        onSubmit(inputRef.current?.value ?? '');
    }, [onSubmit]);



    const onResetHandle = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.value = '';
            setShowReset(false);
        }
        reset?.();
    }, [reset]);


    const onChangeHandle = useCallback((value: string) => {
        setShowReset(value !== '' ? true : false);
    }, []);


    const onfocusHandle = useCallback(() => {
        configureKeyboard({
            type: 'qwerty',
            inputRef: inputRef,
            onChange: onChangeHandle,
            enterLabel: 'Buscar',
            onSubmit: (ref, value) => onSubmit(value)
        });
    }, [configureKeyboard, onChangeHandle, onSubmit]);


    const endArdoment = useMemo(() => <>{reset && showReset && <IconButton onClick={onResetHandle} ><Icon>clear</Icon></IconButton>}<IconButton onClick={onSubmitHandle} ><Icon>search</Icon></IconButton></>, [reset, showReset, onResetHandle, onSubmitHandle]);

    return (<>
        <div id={styles.SearchInput} >
            {/* <Autocomplete getOptionLabel={option => option.number} renderInput={params => <TextField {...params} inputRef={inputRef} />} options={tables} /> */}
            <KeyboardInput
                inputRef={inputRef}
                endAdornment={endArdoment}
                onChange={onChangeHandle}
                onFocus={onfocusHandle}
                label={''}
                size='small'
                onSubmit={(ref, value) => onSubmit(value)}
            />
        </div>
    </>
    )
}
export default SearchInput