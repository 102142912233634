import { ArrowRight, KeyboardArrowRight } from '@mui/icons-material';
import { Button, Drawer } from '@mui/material';
import React, { FC, useState } from 'react'
import { IExtract } from '../../../../modules/consume/domain/models/Extract'
import { IOrderPad } from '../../../../modules/consume/domain/models/OrderPad';
import ExtractOrders from './components/ExtractOrders';
import styles from './ExtractResume.module.scss'
export interface IExtractResumeProps {
    //propertys
    extract?: IExtract;
    orderPad?: IOrderPad;
    tag?: string,
    number?: string,
    onLoading?: boolean
    onClickReadTag?: () => void
}
const ExtractResume: FC<IExtractResumeProps> = ({ extract, tag, number,onClickReadTag, orderPad, onLoading }) => {

    const [extractModalOpen, setExtractModalOpen] = useState(false)
    return (
        onLoading ? <div id={styles.ExtracLoading}>Carregando extrato</div> :
            !extract ? <div id={styles.ExtracNotFound}><span>
                Extrato não encontrado
            </span>
                <div className={styles.readOrderPadTagButton} onClick={ onClickReadTag} >Ler comanda novamente</div>
            </div> :
                <div id={styles.ExtractResume} >
                    {/* <div className={styles.imageContainer} >
                    <div className={styles.imageContainer} ></div>
                </div> */}
                    <div className={styles.container} >
                        <div className={styles.user} >
                            <div className={styles.user} >
                                <div className={styles.name} >
                                    {extract.user?.name ?? "Nome não informado"}
                                </div>
                            </div>
                            <div className={styles.orderPad} >
                                <span >
                                    Comanda: <b>{tag}</b>
                                </span>
                                <span  >
                                    Mesa:<b> {number}</b>
                                </span>
                            </div>
                        </div>

                        <div className={styles.data} >
                            <div className={styles.item}>
                                <span>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(extract.totalToPayWithFee)}</span>
                                <label>Saldo</label>
                            </div>
                            <div className={styles.item}>
                                <span>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(extract.totalAmount)}</span>
                                <label>Total Consumido</label>
                            </div>
                            <div className={styles.item}>
                                <span>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(extract.totalDiscount)}</span>
                                <label>Desconto</label>
                            </div>
                            {/* <div className={styles.item}>
                            <span>{extract.paidAmount}</span>
                            <label>Total a pagar</label>
                        </div> */}
                            <div className={styles.item}>
                                <span>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(extract.minimumConsumption)}</span>
                                <label>Minimo</label>
                            </div>
                            {/* <div className={styles.item}>
                            <span>{extract.totalToPayWithoutFee}</span>
                            <label>Total</label>
                        </div> */}
                        </div>
                        <Button onClick={() => setExtractModalOpen(true)} ><u>Visualizar Extrato</u> <KeyboardArrowRight /> </Button>
                    </div>
                    <Drawer anchor='right' open={extractModalOpen} onClose={() => setExtractModalOpen(false)}>
                        <ExtractOrders setExtractModalOpen={setExtractModalOpen} extract={extract}></ExtractOrders>
                    </Drawer>
                </div >
    )
}
export default ExtractResume