import {ISharedOrderPadRepository} from '../../../domain/interfaces/sharedOrderPad/ISharedOrderPadRepository';
import {ISharedOrderPad} from '../../../domain/models/SharedOrderPad';

const GetLocalSharedOrderPadListUseCase = async (
  sharedOrderPadRepository: ISharedOrderPadRepository,
): Promise<ISharedOrderPad[] | null> => {
  const response = await sharedOrderPadRepository.getSharedOrderPads();
  return response;
};
export default GetLocalSharedOrderPadListUseCase;
