import { IOrder } from "../../cart/domain/models/IOrder";
import { ILocalPrinterService } from "../domain/interfaces/ILocalPrinterService";


const SendToLocalPrinterUseCase = async (
  localPrinterService: ILocalPrinterService,
  tagOrNumber: string,
  deviceName: string,
  order: IOrder,
  printerServeIp?: string,
) => {
  await localPrinterService.sendToLocalPrinter(order, tagOrNumber, deviceName, printerServeIp);
};
export default SendToLocalPrinterUseCase;
