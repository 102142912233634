import React, { FC, useCallback, useEffect, useState } from 'react'
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { ICartItem } from '../../modules/cart/domain/models/ICart';
import { useCart } from '../../modules/cart/presentation/contexts/CartContext';
import UseComsumeOrder from '../../modules/consume/presentation/UseConsumeOrder';
import UseExtract from '../../modules/consume/presentation/UseExtract'
import UseOrderPad from '../../modules/consume/presentation/UseOrderPad'
import { useDevice } from '../../modules/device/presentation/context/DeviceContext';
import { useUi } from '../../modules/ui/presentation/context/UiContext';
import { v4 } from 'uuid'
import UseLocalPrinter from '../../modules/localPrinter/presentation/UseLocalPrinter';
import { IOrder } from '../../modules/cart/domain/models/IOrder';
import { SystemType } from '../../modules/device/domain/enums/SystemType';
import { OrderPadType } from '../../modules/device/interface/Device';
const UseCatalogPage = () => {
    const { getOrderPad, orderPad } = UseOrderPad();
    const { getExtract, extract } = UseExtract();
    const { device } = useDevice();
    const { cartItems, clearCart } = useCart();
    const navigate = useNavigate();
    const { consumeOrder } = UseComsumeOrder();


    const { sendOrderToLocalPrint } = UseLocalPrinter()

    const [onLoadingExtract, setOnLoadingExtract] = useState(true)

    const { tag, operatorId, number } = useParams();
    const { fullScreenToast, toast } = useUi();

    const getExtractAndOrderPad = useCallback(
        async (tag: string) => {
            if (device && !device.fromStorage) {
                try {
                    setOnLoadingExtract(true)
                    const orderPadResponse = await getOrderPad(tag, device.localId);
                    const extractResponse = await getExtract(orderPadResponse.id, device.localId);
                    console.log('EXTRACT', orderPadResponse, extractResponse)
                } catch (e) {
                    console.error(e)
                } finally {
                    setOnLoadingExtract(false)
                }
            }

        }, [device, getExtract, getOrderPad],
    )


    useEffect(() => {
        if (tag) {
            getExtractAndOrderPad(tag);
        }


    }, [getExtractAndOrderPad, tag])

    const onClickBackHandle = useCallback(
        () => {
            navigate("/", { replace: true });
            clearCart();
        },
        [clearCart, navigate],
    )

    useEffect(() => {
        const keyDownHandler = event => {
            console.log('User pressed: ', event.key);

            if (event.key === 'Escape') {
                event.preventDefault();

                // 👇️ your logic here
                onClickBackHandle();
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        // 👇️ clean up event listener
        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, [onClickBackHandle]);



    const onClickSendButtonHandle = useCallback(
        async () => {

            console.log("ON CLICK SEND", orderPad, extract, number, operatorId)

            if (device?.configSymstem.find(item => item.systemType === SystemType.pospayOrderPad)) {
                // try {
                if (device.orderPadType === OrderPadType.PERSONAL) {

                    if (orderPad && extract && operatorId) {

                        const orderId: string = v4()
                        await consumeOrder(orderPad?.id, orderPad?.accountId, cartItems, number ?? "", v4(), operatorId)
                        fullScreenToast("Enviado com sucesso", 'success')
                        onClickBackHandle();

                        const order: IOrder = {
                            id: orderId,
                            orderItem: cartItems,
                        }
                        if (device?.configSymstem.find(item => item.systemType === SystemType.pospayOrderPad)?.enableRemotePrint) {
                            sendOrderToLocalPrint(order, number ?? "");
                        }
                    }

                } else {
                    toast("Comanda compartilhada ainda nao foi implementada. Verifique as configurações do estabelecimento",'warning')
                }
            }else{
                toast("Pre pago e ficha ainda nao foi implementada. Verifique as configurações do dispositivo",'warning')
            }
        },
        [cartItems, consumeOrder, device, extract, fullScreenToast, number, onClickBackHandle, operatorId, orderPad, sendOrderToLocalPrint, toast],
    )


    return ({
        extract, orderPad, tag, number, onLoadingExtract, onClickBackHandle, onClickSendButtonHandle
    })
}



export default UseCatalogPage