
import React from 'react'
import { IOrderComposition } from '../../../../../modules/cart/domain/models/IOrderComposition'
import { IOrderCompositionItem } from '../../../../../modules/cart/domain/models/IOrderCompositionItem'
import { IProductComposition } from '../../../../../modules/catalog/domain/models/IProductComposition'
import { IProductCompositionItem } from '../../../../../modules/catalog/domain/models/IProductCompositionItem'
import { calcPricFreeQuantity, getProductCompositionOfCompositionItemId } from './BaseCompositionUseCase'
import { CalcTotalsAllCompositions } from './CalcTotalsAllCompositions'
import { CalcTotalsOrderComposition } from './CalcTotalsComposition'

export const AddOrderCompositionByProductCompositionItem = (prevOrderComposition: IOrderComposition[], productCompositions: IProductComposition, productCompositionItem: IProductCompositionItem, quantity: number): IOrderComposition[] => {


    const verifyIfExistComposition = (prevOrderComposition: IOrderComposition[], __productComposition: IProductComposition): IOrderComposition | undefined => {
        const orderComposition = prevOrderComposition.find(_composition => _composition.id === __productComposition.id);
        return orderComposition
    }

    const veryfyIfExistCompositionItem = (_prevOrderComposition: IOrderComposition[], __productComposition: IProductComposition, _productCompositionItem: IProductCompositionItem): IOrderCompositionItem | undefined => {
        const orderCompositionItem = _prevOrderComposition.find(_composition => _composition.id === __productComposition.id)?.compositionItems.find(_compositionItem => _productCompositionItem.id === _compositionItem.id);
        return orderCompositionItem
    }



    /**
     * Adiciona um novo Item da composição
     * @param _productCompositionItem 
     * @param _quantity 
     */
    const addNewCompositionItem = (_prevOrderComposition: IOrderComposition[], _productComposition: IProductComposition, _productCompositionItem: IProductCompositionItem, _quantity: number) => {
        const newCompositionItem = productCompositionItemToOrderCompositionItem(_productCompositionItem, _quantity);
        const newQuantityTotalCompositions = CalcTotalsOrderComposition(_prevOrderComposition, _productComposition).quantity + _quantity;
        if (!_productComposition.max || newQuantityTotalCompositions <= _productComposition.max) {
            if (verifyIfExistComposition(_prevOrderComposition, _productComposition)) {
                return _prevOrderComposition.map(_composition => {
                    if (_composition.id === _productComposition.id) {
                        return {
                            ..._composition,
                            compositionItems: [..._composition.compositionItems, { ...newCompositionItem, quantity: _quantity }]
                        }
                    } else {
                        return _composition;
                    }
                })
            } else {
                const newComposition: IOrderComposition = productCompositionToOrderComposition(_productComposition, [newCompositionItem]);
                return [..._prevOrderComposition, newComposition]
            }
        } else {
            return [..._prevOrderComposition]
        }
    }

    const addQuantityInExistCompositionItem = (_prevOrderComposition: IOrderComposition[], _productComposition: IProductComposition, _productCompositionItem: IProductCompositionItem, _quantity: number) => {
        const newQuantityTotalComposition = CalcTotalsOrderComposition(_prevOrderComposition, _productComposition).quantity + _quantity
        if (!_productComposition.max || newQuantityTotalComposition <= _productComposition.max) {
            const newOrderComposition = _prevOrderComposition.map((_composition) => {
                if (_composition.id === _productComposition.id) {
                    const newCompositionItems = _composition.compositionItems.map(_compositionItem => {
                        if (_compositionItem.id === _productCompositionItem.id) {
                            const newQuantity = _compositionItem.quantity + _quantity
                            if (!_productCompositionItem.max || newQuantity <= _productCompositionItem.max) {
                                return {
                                    ..._compositionItem,
                                    quantity: newQuantity,
                                    totalPrice: calcPricFreeQuantity(_productCompositionItem.price, newQuantity, _productCompositionItem.quantityFree)
                                }
                            } else {
                                console.error("CompositionItem overflow max");
                            }
                            return _compositionItem
                        } else {
                            return _compositionItem
                        }
                    })
                    return { ..._composition, compositionItems: newCompositionItems }
                } else {
                    return _composition;
                }
            })
            return newOrderComposition
        } else {
            console.error("Composition overflow max");
        }
        return _prevOrderComposition;
    }


    const addProductAndClearOrderWhenMinMaxOne = (_prevOrderComposition: IOrderComposition[], _productComposition: IProductComposition, _productCompositionItem: IProductCompositionItem) => {
        const orderCompositionsWithCompositionCleaned = _prevOrderComposition.map((orderComposition) => {
            if (orderComposition.id === _productComposition.id) {
                return { ...orderComposition, compositionItems: [] }
            } else {
                return orderComposition
            }
        })

        return addNewCompositionItem(orderCompositionsWithCompositionCleaned, _productComposition, _productCompositionItem, 1);

    }

    const productCompositionItemToOrderCompositionItem = (productCompositionItem: IProductCompositionItem, quantity: number): IOrderCompositionItem => {
        return {
            id: productCompositionItem.id,
            description: productCompositionItem.description,
            price: productCompositionItem.price,
            totalPrice: calcPricFreeQuantity(productCompositionItem.price, quantity, productCompositionItem.quantityFree),
            imageUrl: productCompositionItem.imageUrl,
            quantity: quantity,
            quantityFree: productCompositionItem.quantityFree,
            productId: productCompositionItem.productId,
            produtoId: productCompositionItem.produtoId
        }
    }
    const productCompositionToOrderComposition = (productComposition: IProductComposition, orderCompositionItems: IOrderCompositionItem[]): IOrderComposition => {
        return {
            id: productComposition.id,
            description: productComposition.description,
            imageUrl: productComposition.imageUrl,
            compositionItems: orderCompositionItems
        }
    }


    const addOrderCompositionByCompositionItem = (_prevOrderComposition: IOrderComposition[], _productComposition: IProductComposition, _productCompositionItem: IProductCompositionItem, _quantity: number) => {
        if (_productComposition.min === 1 && _productComposition.max === 1) {
            //se o minimo e maximo for igual a 1 limpar e adicionar novo item
            // console.log("MINMAX = 1");
            return addProductAndClearOrderWhenMinMaxOne(_prevOrderComposition, _productComposition, _productCompositionItem)
        } else {
            if (veryfyIfExistCompositionItem(_prevOrderComposition, _productComposition, _productCompositionItem)) {
                return addQuantityInExistCompositionItem(_prevOrderComposition, _productComposition, _productCompositionItem, _quantity)
            } else {
                return addNewCompositionItem(_prevOrderComposition, _productComposition, _productCompositionItem, _quantity)
            }
        }

    }

    return (
        addOrderCompositionByCompositionItem(prevOrderComposition, productCompositions, productCompositionItem, quantity)
    );
}
