import {AxiosInstance} from 'axios';
import {GetSharedOrderPadsResponse} from '../../../modules/consume/domain/dtos/GetSharedOrderPadResponse';
import {ISharedOrderPad} from '../../../modules/consume/domain/models/SharedOrderPad';

const AnticorruptionConsumeApi = (api: AxiosInstance) => {
  const getSharedOrderOldApi = async (localId: string): Promise<GetSharedOrderPadsResponse[]> => {
    const response = await api.get<
      {
        id: string;
        numero: string;
        abertoEm?: string;
        dataInicioFechamento?: string;
        tipo?: number;
        documento?: string;
        saldo?: number;
        status?: any;
      }[]
    >(`dispositivo/${localId}/comandas/1`);
    return response.data.map(item => ({id: item.id, number: item.numero}));
  };

  return {getSharedOrderOldApi};
};
export default AnticorruptionConsumeApi;
