import { useCallback } from 'react';
import { v4 } from 'uuid';
import ConsumeApi from '../../../services/api/consume/ConsumeApi';
import { useDevice } from '../../device/presentation/context/DeviceContext';
import { IOrderItem } from '../../cart/domain/models/IOrderItem';
import { useUi } from '../../ui/presentation/context/UiContext';
import SendConsumeOrderUseCase from '../application/useCases/consume/SendConsumeOrderUseCase';
const UseComsumeOrder = () => {
  const { device } = useDevice();
  const { showLoading, hideLoading } = useUi();

  const consumeOrder = useCallback(
    async (
      orderPadId: string,
      accountId: string,
      orderItems: IOrderItem[],
      sharedOrderPadNumber: string,
      orderId: string = v4(),
      operatorId: string
    ) => {
      try {
        showLoading('@consumeOrder', 'Enviando pedido');
        const consumeService = ConsumeApi();
        if (device) {
          await SendConsumeOrderUseCase(
            consumeService,
            device?.localId,
            device?.id,
            orderPadId,
            accountId,
            orderItems,
            sharedOrderPadNumber,
            orderId,
            operatorId
          );
          return {
            orderId: orderId,
          };
        } else {
          console.error('No Device');
          throw '[Consume Order]no Device';
        }
      } catch (error) {
        console.error(error);
        throw error;
      } finally {
        hideLoading('@consumeOrder');
      }
    },
    [device, hideLoading, showLoading],
  );
  return { consumeOrder };
};

export default UseComsumeOrder;
