import axios from "axios";

const BASE_URL = 'https://totem-api.meep.cloud';



const api = axios.create({
  baseURL: BASE_URL + '/api',
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
  },
});

export const Api = () => {
  return api;
};

export const LoginApi = axios.create({
  baseURL: BASE_URL + '/api',
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
  },
});
export const ApiLogin = () => {
  return LoginApi;
};
