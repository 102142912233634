import React, { FC, memo, useMemo } from 'react'
import { IProductComposition } from '../../../../../../modules/catalog/domain/models/IProductComposition'
import { useComposition } from '../../context/CompositionContext'
import BoxProductComposition from '../boxProductComposition/BoxProductComposition'
import BoxProductCompositionItem from '../boxProductCompositionItem/BoxProductCompositionItem'
import styles from './ProductCompositionList.module.scss'
export interface IProductCompositionListProps {
    //propertys
    productCompositions: IProductComposition[]
}
const ProductCompositionList: FC<IProductCompositionListProps> = memo(({ productCompositions }) => {
    const { orderCompositions } = useComposition();
    return (
        <div id={styles.ProductCompositionList} >
            <div className={styles.container} >
                {
                    productCompositions.map((productComposition) => (
                        <BoxProductComposition productComposition={productComposition} />
                    ))
                }
            </div>
        </div>
    )
})
export default ProductCompositionList