import {ISharedOrderPadRepository} from '../../../domain/interfaces/sharedOrderPad/ISharedOrderPadRepository';
import {ISharedOrderPad} from '../../../domain/models/SharedOrderPad';

const SetCurrentSharedOrderPadUseCase = async (
  sharedOrderPadRepository: ISharedOrderPadRepository,
  orderPad: ISharedOrderPad,
): Promise<void> => {
  await sharedOrderPadRepository.setCurrentSharedOrderPad(orderPad);
};
export default SetCurrentSharedOrderPadUseCase;
