// import {ErrorCodeType} from '@/models/error/IError';
// import {GetError} from '@/utils/functions/GetError';
import { IOperatorDTO } from '../../domains/dto/OperatorDTO';
import { IOperator } from '../../domains/models/Operator';
import { IOperatorRepository } from '../../domains/interfaces/IOperatorRepository';
import { IOperatorService } from '../../domains/interfaces/IOperatorService';

export const GetOperatorsUseCase = async (
  service: IOperatorService,
  repository: IOperatorRepository,
  localId: string,
): Promise<IOperator[]> => {
  let responseOperator: IOperator[] | null;
  try {
    responseOperator = await service.getAll(localId);
    repository.save(responseOperator);
    return responseOperator;
  } catch (error) {
    console.error('[API]', error);
    responseOperator = await repository.getAll();
    if (responseOperator) {
      return responseOperator
    } else {
      throw (new Error("Nenhum operador encontrado"))
    }
  } finally {
  }
};
