import {IDeviceResponse} from '../../../../modules/device/domain/dtos/DeviceResponse';
import {OrderPadType} from '../../../../modules/device/interface/Device';

import {Api} from '../../bases/Api';
import {IUsuarioMeepPos} from './IUsuarioMeepPos';

const AnticorruptionDeviceService = () => {
  const getDeviceConfig = async (deviceId: string, mac: string) => {
    const api = Api();
    console.log("device token",api.defaults.headers.common)
    const reponseConfigMeep = await api.post<IUsuarioMeepPos>('/UsuarioMeepPOS/AtualizarDadosEquipamento', {
      IMEI: mac,
      UsuarioMeepPOSId: deviceId,
    });

    return {
      ...configTotemMeepToDevice(reponseConfigMeep.data),
    };
  };

  return {getDeviceConfig};
};
export default AnticorruptionDeviceService;

export const configTotemMeepToDevice = (configMeep: IUsuarioMeepPos): IDeviceResponse => {
  const _device: IDeviceResponse = {
    id: configMeep.usuarioMeepPOSId,
    generateEletronicInvoice: configMeep.gerarNfce,
    mustPrintEletronicInvoice: configMeep.deveImprimirNFCe,
    // hasSplitInvoice: configMeep.temSplitFiscal,
    barCodeMode: configMeep.somenteLeitorCodigoBarra,
    enableBarCode: configMeep.aceitaLeitorCodigoBarra,
    localName: configMeep.localNome,
    token: '',
    localId: configMeep.localId,
    printType: configMeep.tipoImpressao,
    addressLocalServer: configMeep.enderecoServidorLocal,
    printCategoryInProductName: configMeep.imprimirCategoriaProduto,
    wallpaperImageUrl: configMeep.papelDeParedeUrl,
    customerField: configMeep.camposClienteEstabelecimento?.toLocaleLowerCase().split(';') ?? [],
    customerFieldEnable: configMeep.identificarClienteEstabelecimento,
    customerFieldDisabled: configMeep.desativarIdentificacaoCliente,
    paymentVoucher: configMeep.formaPagamentoVoucher,
    deviceName: configMeep.usuarioMeepPOSApelido,
    orderPad:
      (
        JSON.parse(configMeep.comandas) as {
          id: string;
          numero: string;
        }[]
      )?.map(item => ({id: item.id, number: item.numero})) ?? [],
    configSymstem: configMeep.configuracaoSistemasGestao?.map(configGestao => ({
      systemType: configGestao.tipoSistema.id,
      printType: configGestao.tipoImpressao.id,
      enableRemotePrint: configGestao.impressoraRemotaHabilitada,
    })),
    serviceRate: configMeep.deveCobrarTaxaServico,
    serviceRatePercent: configMeep.percentualTaxaServico,
    messageOnPayment: configMeep.mensagemAposPagamento ?? 'Pedido realizado com Sucesso',
    printReceipt: configMeep.estaImpressaoComprovanteHabilitado,
    newPrinter: true,
    paymentCashless: configMeep.cartaoRotativo,
    weighingMachineMode: true,

    paymentDebit: configMeep.cartaoCredito,
    paymentCredit: configMeep.cartaoDebito,
    enableSchedule: configMeep.habilitarAgendamento,
    // onlyNextTime: true,
    enableInstallment: false,
    // hideZeroPrice: true, //TODO: Oculta valores zerados
    //configMeep.agendamentoApenasProximoHorario,
    // newPrinter: configMeep.imprimeCartaoRfid
    orderToGo: configMeep.devePerguntarSePedidoEParaViagem,
    orderPadType: configMeep.tipoComandaEletronica.id === 2 ? OrderPadType.PERSONAL : OrderPadType.SHARED,
    localServerAddress: configMeep.enderecoServidorLocal,
  };
  return _device;
};
