
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useParams } from 'react-router';
import { v4 } from 'uuid';
import { IOrderComposition } from '../../../../modules/cart/domain/models/IOrderComposition';
import { useCart } from '../../../../modules/cart/presentation/contexts/CartContext';

import { IProduct } from '../../../../modules/catalog/domain/models/IProduct';
import { IProductComposition } from '../../../../modules/catalog/domain/models/IProductComposition';
import { ITotalsComposition } from '../productComposition/interfaces/ITotalsComposition';

const useProductDetail = (product: IProduct, onClickAddProduct: (product: IProduct, quantity: number, compositions?: IOrderComposition[]) => void) => {


    const { url, storeId } = useParams<{ url: string, storeId: string }>();

    const [quantity, setQuantity] = useState(1);
    const [observationField, setObservationField] = useState<string>()
    const [compositions, setCompositions] = useState<{ orderComposition: IOrderComposition[], totals: ITotalsComposition, unvalidatedCompositions: IProductComposition[] }>();


    const onClickAddQuantityHandle = useCallback(() => {
        setQuantity(prev => prev + 1);
    }, [])
    const onClickRemoveQuantityHandle = useCallback(() => {
        setQuantity(prev => prev > 1 ? prev - 1 : 1);
    }, [])

    const onClickAddProductHandle = useCallback(
        () => {
            if (product) {

                const _composition: IOrderComposition[] = [...(compositions?.orderComposition ? compositions.orderComposition : [])]


                const _observationField = observationField ? {
                    id: v4(),
                    description: 'Observação',
                    compositionItems: [
                        {
                            id: v4(),
                            description: observationField,
                            price: 0,
                            totalPrice: 0,
                            quantity: 1,
                        }
                    ]
                } : null

                onClickAddProduct(product, quantity, _observationField ? [..._composition, _observationField] : _composition);
            }

        },
        [product, compositions, observationField, onClickAddProduct, quantity],
    )
    const onChangeCompositionHandle = useCallback(
        (orderComposition: IOrderComposition[], totals: ITotalsComposition, unvalidatedCompositions: IProductComposition[]) => {
            setCompositions({ orderComposition, totals, unvalidatedCompositions })
        },
        [],
    )

    const handleChangeObservation = (evt: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { value } = evt.target;
        setObservationField(value);
    };


    return (
        {
            product,
            compositions,
            quantity,
            onClickAddQuantityHandle,
            onClickRemoveQuantityHandle,
            onClickAddProductHandle,
            onChangeCompositionHandle,
            handleChangeObservation
        }
    )
}
export default useProductDetail
