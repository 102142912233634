import axios, { Axios, AxiosInstance, AxiosResponse } from 'axios';
import { v4 } from 'uuid';
import { ILocalPrinterOrder } from '../../../../modules/localPrinter/domain/dtos/ILocalPrinterOrder';
import {
  IMeepImpressoraBody,
  IMeepImpressoraPostObject,
  IMeepImpressoraCancelamentoBody,
  IMeepImpressoraProdutoDetails,
  IMeepImpressoraNotes,
} from './Impressora/IMeepImpressora';

const AnticorruptionLocalPrinter = (api: AxiosInstance, printerServerIp: string) => {
  //   const api = axios.create({url: printerServerIp});

  const EnviarPedidoParaImpressora = async (
    order: ILocalPrinterOrder,
    tagOrNumber: string,
    impressoraNome: string,
    pontoDeVenda: string,
  ): Promise<any> => {
    let body: IMeepImpressoraBody = {
      businessName: 'iotix',
      date: new Date().toISOString(),
      id: v4(),
      pointOfSale: pontoDeVenda,
      responsable: 'AutoAtendimento',
      details: _OrderToPedidoMeep(order),
      pedidoPOSId: v4(),
      tag: tagOrNumber,
    };

    let postObject: IMeepImpressoraPostObject = {
      id: v4(),
      printer: impressoraNome,
      submittedAt: new Date().toISOString(),
      queuedAt: new Date().toISOString(),
      type: 1, //verificar
      body: JSON.stringify(body),
    };
    console.log('postObjectPrint', postObject);
    const result: AxiosResponse = await api.post(printerServerIp + '/api/printer', postObject);
    return result;
  };

  const EnviarCancelamentoParaImpressora = async (
    pedido: ILocalPrinterOrder,
    impressoraNome: string,
    pontoDeVenda: string,
  ) => {
    let body = _OrderToCancelamentoArrayBody(pedido, pontoDeVenda);
    let postObject = {
      id: v4(),
      printer: impressoraNome,
      submittedAt: new Date().toISOString(),
      queuedAt: new Date().toISOString(),
      type: 4, //verificar
      order: 0,
      status: 0,
      body: JSON.stringify(body),
    };
    const result: AxiosResponse = await api.post('/api/printer', postObject);
    console.log('result Cancelamento Print:(' + result.status + '):', result.data);
    return result;
  };

  function _OrderToCancelamentoArrayBody(
    pedido: ILocalPrinterOrder,
    pontoDeVenda: string,
  ): IMeepImpressoraCancelamentoBody[] {
    return pedido.orderItem.map(pedidoItem => {
      let returnBody: IMeepImpressoraCancelamentoBody = {} as IMeepImpressoraCancelamentoBody;
      returnBody.canceledBy = 'AUTO ATENDIMENTO CANCEL';
      returnBody.productName = pedidoItem.name;
      returnBody.productNotes = '';
      returnBody.canceledQuantity = pedidoItem.quantity;
      returnBody.table = pontoDeVenda;
      returnBody.orderNumber = '000';
      returnBody.pointOfSale = pontoDeVenda;
      return returnBody;
    });
  }
  function _OrderToPedidoMeep(order: ILocalPrinterOrder): IMeepImpressoraProdutoDetails[] {
    return order.orderItem.map(orderItem => {
      const _details: IMeepImpressoraProdutoDetails = {
        productName: orderItem.name,
        value: orderItem.productPrice, //rever
        quantity: orderItem.quantity,
        notes:
          orderItem.orderComposition?.map(composition => {
            let _notes: IMeepImpressoraNotes = {
              description: composition.description,
              options: composition.compositionItems.map(compositionItem => {
                return compositionItem.description + 'R$' + compositionItem.price.toFixed(2);
              }),
            };
            return _notes;
          }) ?? [],
      };
      return _details;
    });
  }

  return { EnviarPedidoParaImpressora };
};
export default AnticorruptionLocalPrinter;
