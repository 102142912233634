import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { OperatorService } from '../../../services/api/operator/OperatorService';
import { OperatorRepository } from '../../../services/localStorage/operator/OperatorLocalStorage';
import { useDevice } from '../../device/presentation/context/DeviceContext';
import { useUi } from '../../ui/presentation/context/UiContext';
import { GetOperatorsFromServerUseCase } from '../application/useCases/GetOperatorsFromLocalUseCase';
import { GetOperatorsUseCase as GetOperatorsUseCase } from '../application/useCases/GetOperatorsUseCase';
import ValidateOperatorUseCase from '../application/useCases/ValidateOperatorUseCase';
import { IOperator } from '../domains/models/Operator';

export interface ICreateRefferedPromise<T> {
  promise?: Promise<T>;
  resolver?: ((value: T) => void) | null;
  rejecter?: ((error: any) => void) | null;
}
export interface IValidateOperatorResponse {
  validated: boolean;
  message: string;
}

const UseOperator = () => {
  const [operators, setOperators] = useState<IOperator[]>();
  const { device } = useDevice();
  const { showLoading, hideLoading, toast } = useUi();
  const [validateModalOpen, setValidateModalOpen] = useState(false);

  const validateOperatorPromise = useRef<ICreateRefferedPromise<IValidateOperatorResponse>>();

  const onPressCloseValidateModal = useCallback(() => {
    validateOperatorPromise.current?.rejecter?.('Closed Operator Modal');
    setValidateModalOpen(false);
  }, []);

  const getOperators = useCallback(async () => {
    const operatorService = OperatorService();
    const operatorLocalStorage = OperatorRepository();
    try {
      if (device && !device.fromStorage) {
        showLoading('@GETOPERATOR', 'Obtendo Operadores');
        const response = await GetOperatorsUseCase(operatorService, operatorLocalStorage, device?.localId);
        // console.table(response);
        setOperators(response);
        return response;
      }
    } finally {
      hideLoading('@GETOPERATOR');
    }
  }, [device]);

  const getLocalOperators = useCallback(async () => {
    const operatorService = OperatorService();
    const operatorLocalStorage = OperatorRepository();
    try {

      showLoading('@GETOPERATOR', 'Obtendo Operadores');
      const response = await GetOperatorsFromServerUseCase(operatorLocalStorage);
      // console.table(response);
      if (response) {
        setOperators(response);
      }
      return response;

    } finally {
      hideLoading('@GETOPERATOR');
    }
  }, [device]);

  const onPressValidate = useCallback(
    async (operatorId: string, password: string) => {
      try {
        // const operatorsResponse = await getOperators();
        const operatorsResponse = operators;
        if (operatorsResponse?.length) {
          if (ValidateOperatorUseCase(operatorsResponse, operatorId, password)) {
            validateOperatorPromise.current?.resolver?.({ validated: true, message: 'Validado com sucesso' });
            onPressCloseValidateModal();
          } else {
            toast('Operador ou senha inválidos');
            validateOperatorPromise.current?.rejecter?.(new Error('Operador ou senha inválidos'));
          }
        } else {
          toast('Nenhum operador vinculado ');
          validateOperatorPromise.current?.rejecter?.(new Error('Nenhum operador vinculado '));
          onPressCloseValidateModal();
        }
      } catch (error) {
        validateOperatorPromise.current?.rejecter?.(error);
        onPressCloseValidateModal();
      }
    },
    [onPressCloseValidateModal, operators, toast],
  );

  const validateOperator = useCallback(async () => {
    await getOperators();
    setValidateModalOpen(true);
    const promise = new Promise<IValidateOperatorResponse>((_resolve, _reject) => {
      validateOperatorPromise.current = { ...validateOperatorPromise.current, rejecter: _reject };
      validateOperatorPromise.current = { ...validateOperatorPromise.current, resolver: _resolve };
    });
    validateOperatorPromise.current = { ...validateOperatorPromise.current, promise: promise };
    return promise;
  }, [getOperators]);


  return { operators, validateOperator, getOperators, getLocalOperators, onPressValidate, validateModalOpen, onPressCloseValidateModal };
};

export default UseOperator;
