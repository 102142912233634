import React, { createContext, FC, useContext, useEffect, useCallback, ReactNode } from 'react';
import { Api, LoginApi } from '../../../services/api/bases/Api';
import { useUi } from '../../ui/presentation/context/UiContext';

interface IHttpContext { }
const __DEV__ = true
const HttpContext = createContext<IHttpContext>({} as IHttpContext);

export const HttpProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const { toast } = useUi();

  const onError = useCallback(
    (error: any) => {
      console.error('[API Error]: ', error?.response);
      if (error?.response?.status === 401) {
        toast(
          (__DEV__ && error?.request?.baseURL) + 'Acesso não autorizado verifique se o terminal foi cadastrado',
          'error',
        );
      } else if (error?.response?.data?.message) {
        toast(error?.response?.data?.message, 'error');
      } else if (error?.response?.data?.Message) {
        toast(error?.response?.data?.Message, 'error');
      } else if (error?.response?.data) {
        toast(error?.response?.data, 'error');
      } else if (error?.response) {
        toast('Algo de errado  (' + error.response.status + ')', 'error');
      } else {
        toast('Algo de errado não está certo', 'error');
      }

      throw error;
    },
    [],
  );

  useEffect(() => {
    console.log("SET HTTP ERROR CB")
    Api().interceptors.response.use(value => value, onError);
    LoginApi.interceptors.response.use(value => value, onError);
    return () => { };
  }, []);

  return (
    <HttpContext.Provider value={{}}>
      <>{children}</>
    </HttpContext.Provider>
  );
};

export const useHttp = () => {
  const context = useContext(HttpContext);
  return context;
};
