import {IConsumeService} from '../../../domain/interfaces/IConsumeService';
import {IOrderPad} from '../../../domain/models/OrderPad';

const GetOrderPadUseCase = async (
  consuptionService: IConsumeService,
  localId: string,
  tag: string,
): Promise<IOrderPad> => {
  const response = await consuptionService.getOrderPad(localId, tag);
  return response;
};
export default GetOrderPadUseCase;
