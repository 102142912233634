import { useCallback, useEffect, useState } from 'react';
import ConsumeApi from '../../../services/api/consume/ConsumeApi';
import SharedOrderPadLocalStorage from '../../../services/localStorage/sharedOrderPad/SharedOrderPadLocalStorage';
import { useAuthentication } from '../../authentication/presentation/contexts/AuthenticationContext';
import { useDevice } from '../../device/presentation/context/DeviceContext';
import { useUi } from '../../ui/presentation/context/UiContext';
import GetCurrentSharedOrderPadUseCase from '../application/useCases/sharedOrderPad/GetCurrentSharedOrderPadUseCase';
import GetLocalSharedOrderPadListUseCase from '../application/useCases/sharedOrderPad/GetLocalSharedOrderPadListUseCase';
import GetSharedOrderPadUseCase from '../application/useCases/sharedOrderPad/GetSharedOrderPadListUseCase';
import SetCurrentSharedOrderPadUseCase from '../application/useCases/sharedOrderPad/SetCurrentSharedOrderPadUseCase';
import { ISharedOrderPad } from '../domain/models/SharedOrderPad';

const UseSharedOrderPad = () => {
  const [sharedOrderPadList, setSharedOrderPadList] = useState<ISharedOrderPad[]>();
  const [sharedOrderPad, setSharedOrderPad] = useState<ISharedOrderPad>();
  const { device } = useDevice();
  const { showLoading, hideLoading } = useUi();

  const getSharedOrderPad = useCallback(async () => {
    try {
      // showLoading('@getSharedOrderPad', 'Obter comandas compartilhadas');
      const consumptionService = ConsumeApi();
      const sharedOrderPadRepository = SharedOrderPadLocalStorage();
      if (device && !device.fromStorage) {
        const response = await GetSharedOrderPadUseCase(consumptionService, sharedOrderPadRepository, device.localId);
        console.log('[Shared]', response);
        setSharedOrderPadList(response);
        return response;
      }
    } finally {
      // hideLoading('@getSharedOrderPad');
    }
  }, [device]);

  const getLocalSharedOrderPad = useCallback(async () => {
    try {
      const sharedOrderPadRepository = SharedOrderPadLocalStorage();
      const response = await GetLocalSharedOrderPadListUseCase(sharedOrderPadRepository);
      console.log('[Shared]', response);
      if (response) {
        setSharedOrderPadList(response);
      }
      return response;
    } finally {
      // hideLoading('@getSharedOrderPad');
    }
  }, []);

  const getCurrentSharedOrderPad = useCallback(async () => {
    try {
      showLoading('@getCurrentSharedOrderPad', 'Obter comanda compartilhada setada');
      const sharedOrderPadRepository = SharedOrderPadLocalStorage();
      const response = await GetCurrentSharedOrderPadUseCase(sharedOrderPadRepository);
      if (response) {
        setSharedOrderPad(response);
      }
      return response;
    } finally {
      hideLoading('@getCurrentSharedOrderPad');
    }
  }, [hideLoading, showLoading]);

  const setCurrentSharedOrderPad = useCallback(
    async (_sharedorderPad: ISharedOrderPad) => {
      if (device) {
        try {
          showLoading('@SetCurrentSharedOrderPad', 'Salvando comanda compartilhada');
          const sharedOrderPadRepository = SharedOrderPadLocalStorage();
          const response = await SetCurrentSharedOrderPadUseCase(sharedOrderPadRepository, _sharedorderPad);
          setSharedOrderPad(_sharedorderPad);
          return response;
        } finally {
          hideLoading('@SetCurrentSharedOrderPad');
        }
      } else {
        throw '[Shared Order Pad] No Device';
      }
    },
    [device, hideLoading, showLoading],
  );

  return { getSharedOrderPad, sharedOrderPad, setCurrentSharedOrderPad, sharedOrderPadList, getLocalSharedOrderPad };
};

export default UseSharedOrderPad;
