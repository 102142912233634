import localforage from 'localforage';
import { ISharedOrderPadRepository } from '../../../modules/consume/domain/interfaces/sharedOrderPad/ISharedOrderPadRepository';
import { ISharedOrderPad } from '../../../modules/consume/domain/models/SharedOrderPad';

const SharedOrderPadLocalStorage = (): ISharedOrderPadRepository => {
  const key = '@sharedOrderPad';
  const keyCurrent = '@currentSharedOrderPad';

  const saveSharedOrderPads = async (orderPads: ISharedOrderPad[]): Promise<void> => {
    localforage.setItem(key, JSON.stringify(orderPads));
  };
  const getSharedOrderPads = async (): Promise<ISharedOrderPad[] | null> => {
    const response = await localforage.getItem<ISharedOrderPad[]>(key);
    return response;
  };
  const getCurrentSharedOrderPad = async (): Promise<ISharedOrderPad | null> => {
    const response = await localforage.getItem<ISharedOrderPad>(keyCurrent);
    return response;
  };
  const setCurrentSharedOrderPad = async (orderPad: ISharedOrderPad): Promise<void> => {
    localforage.setItem(keyCurrent, JSON.stringify(orderPad));
  };

  return { saveSharedOrderPads, getSharedOrderPads, getCurrentSharedOrderPad, setCurrentSharedOrderPad };
};
export default SharedOrderPadLocalStorage;
