import { useCallback, useState } from 'react';
import ConsumeApi from '../../../services/api/consume/ConsumeApi';
import { useDevice } from '../../device/presentation/context/DeviceContext';
import { useUi } from '../../ui/presentation/context/UiContext';
import GetOrderPadUseCase from '../application/useCases/orderPad/GetOrderPadUseCase';
import { IOrderPad } from '../domain/models/OrderPad';

const UseOrderPad = () => {
  const [orderPad, setOrderPad] = useState<IOrderPad>();
  const { showLoading, hideLoading } = useUi();
  const [onLoadingOrderPad, setOnLoadingOrderPad] = useState(false)

  const getOrderPad = useCallback(
    async (tag: string, localId: string) => {
      try {
        // showLoading('@getUserAccount', 'Obtendo dados da comanda');
        setOnLoadingOrderPad(true)

        const consumptionService = ConsumeApi();
        const response = await GetOrderPadUseCase(consumptionService, localId, tag);
        setOrderPad(response);
        return response;

      } finally {
        // hideLoading('@getUserAccount');
        setOnLoadingOrderPad(false)
      }
    },
    [],
  );

  return { getOrderPad, orderPad, onLoadingOrderPad };
};

export default UseOrderPad;
