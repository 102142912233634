import { useCallback, useEffect, useState } from 'react';
import { AuthenticationRepository } from '../../../../services/localStorage/authentication/AuthenticationLocalStorage';
import { GetMacAddressFromExternalUseCase } from '../../application/useCases/GetMacAddressFromExternalUseCase';
import GetMacAddressUseCase from '../../application/useCases/GetMacAddressUseCase';
import SetMacAddressUseCase from '../../application/useCases/SetMacAddressUseCase';

const UseDeviceInfoContext = () => {
  const [macAddress, setMacAddress] = useState<string>();
  const [firstOpen, setFirstOpen] = useState(false);

  const saveMacAddress = useCallback(async (newMac: string) => {
    const authenticationRepository = AuthenticationRepository();
    await SetMacAddressUseCase(authenticationRepository, newMac);
    setMacAddress(newMac);
    setFirstOpen(false);
  }, []);

  const getLocalMacAddress = useCallback(async () => {
    const authenticationRepository = AuthenticationRepository();
    try {
      const localMacAddress = await GetMacAddressUseCase(authenticationRepository);
      if (!localMacAddress) {
        try {
          // const deviceMac = '5CCD5BE0CE41';//TODO:adicionar get MacAddress
          const deviceMac = await GetMacAddressFromExternalUseCase()
          // console.log("deviceMAC")
          if (deviceMac) {
            SetMacAddressUseCase(authenticationRepository, deviceMac);
            setMacAddress(deviceMac);
          } else {
            throw new Error("MAC ADDRESS NOT FOUND");
          }
        } catch {
          setFirstOpen(true);
        }
      } else {
        setMacAddress(localMacAddress);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getLocalMacAddress();
    // setMacAddress(`0024D67ACBDA`);
    // setMacAddress(`0024D67ACBDA`);
    // setMacAddress(`A0:60:90:7F:18:5C`);
    // setMacAddress(`A0:60:90:89:B9:4C`);
  }, [getLocalMacAddress]);

  return { macAddress, saveMacAddress, firstOpen };
};

export default UseDeviceInfoContext;
