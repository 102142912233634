
import { Button, Icon, IconButton } from '@mui/material';
import React, { FC, useCallback, useEffect, useState } from 'react'
import Input from '../../../../components/ui/input/Input';
import { IOrderComposition } from '../../../../modules/cart/domain/models/IOrderComposition';
import { useCatalog } from '../../../../modules/catalog/context/CatalogContext';
import { IProduct } from '../../../../modules/catalog/domain/models/IProduct';
import ProductCompositions from '../productComposition/ProductCompositions';
import styles from './ProductDetail.module.scss'
import useProductDetail from './UseProductDetail'
export interface IProductProps {
    //propertys
    product: IProduct,
    onClickAddProduct: (product: IProduct, quantity: number, compositions?: IOrderComposition[]) => void
    hideImage?: boolean
}
const ProductDetail: FC<IProductProps> = ({ product, onClickAddProduct, hideImage }) => {



    const {
        compositions,
        quantity,
        onClickAddQuantityHandle,
        onClickRemoveQuantityHandle,
        onClickAddProductHandle,
        onChangeCompositionHandle,
        handleChangeObservation
    } = useProductDetail(product, onClickAddProduct);


    const { catalog } = useCatalog()
    const [disabledAddProduct, setDisabledAddProduct] = useState(true);



    useEffect(() => {
        if (!!compositions?.unvalidatedCompositions.length) {
            setDisabledAddProduct(true)
        } else {
            setDisabledAddProduct(false)
        }
        return () => {

        }
    }, [compositions])

    return (
        product ?
            <div id={styles.ProductDetail} >
                <div className={styles.container} >
                    {!hideImage && <div id={styles.Header} >

                        <div className={styles.container} style={{ backgroundImage: `url(${product.thumbnailUrl})` }} >
                        </div>
                    </div>}
                    <div className={styles.description} >
                        <h2>
                            {product?.name}
                        </h2>
                    </div>
                    <div className={styles.description} >
                        <div className={styles.description} >
                            {product?.description}
                        </div>
                        <div className={styles.price} >
                            <h1>
                                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(product?.price)}
                            </h1>
                        </div>
                    </div>
                    {product.compositions && !catalog?.hideCompositions && <div className={styles.compositions}>
                        <ProductCompositions
                            productCompositions={product.compositions}
                            onChangeOrderCompositions={onChangeCompositionHandle}
                        />
                        <div>
                            {/* {
                                !!product.productGeneralObservation &&
                                <Input
                                    rows={4}
                                    label="Observações"
                                    placeholder=""
                                    multiline
                                    variant='outlined'
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    inputProps={{
                                        maxLength: 200
                                    }}
                                    onChange={handleChangeObservation}
                                />
                            } */}
                        </div>
                    </div>}
                    {
                        !(catalog?.enableShoppingCart === false) &&
                        <div className={styles.footerContainer}>
                            <div className={styles.footer}>
                                <div className={styles.quantity}>
                                    <IconButton
                                        disabled={!(quantity > 1)}
                                        className={!(quantity > 1) ? styles.disabledIcon : styles.buttonIcons}
                                        onClick={onClickRemoveQuantityHandle}><Icon>remove</Icon></IconButton>
                                    {quantity}
                                    <IconButton
                                        className={styles.buttonIcons}
                                        onClick={onClickAddQuantityHandle}
                                    ><Icon>add</Icon></IconButton>
                                </div>
                                <div className={styles.buttonContainer}>
                                    <Button disabled={disabledAddProduct} fullWidth variant="contained" onClick={onClickAddProductHandle} color="primary">
                                        Adicionar {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(quantity * (product.price + (compositions?.totals.price ?? 0)))}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            :
            <div>
                Carregando Produto
            </div>
    )
}
export default ProductDetail
