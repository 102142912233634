import React, { FC, ReactNode, RefObject, forwardRef, useCallback, useEffect, useImperativeHandle, useRef } from 'react'
import styles from './Keyboard.module.scss'
import { Icon, TextField } from '@mui/material'



export interface IKeyboardProps {
    //propertys
    // onPressKey?: (key: string) => void
    onSubmit?: (inputRef: React.RefObject<HTMLInputElement>, value: string) => void;
    enterLabel?: ReactNode;
    lengthToEnter?: number;
    type?: "numeric" | "qwerty";
    inputRef?: React.RefObject<HTMLInputElement>;
    onChange?: (value: string) => void;
    autoFocus?: boolean;
}



const Keyboard: FC<IKeyboardProps> = ({ onSubmit, lengthToEnter = 20, enterLabel = 'Enter', type = 'numeric', onChange, inputRef }, ref) => {
    const _inputRef = inputRef;
    const [numerical, setNumerical] = React.useState(false);
    const [uppercase, setUppercase] = React.useState(false);

    

    const onPressKeyHandle = useCallback((_value: string) => {
        const input = _inputRef?.current;
        if (!input) return;


        if (_value === '123' || _value === 'abc') {
            input.focus();
            setNumerical(prev => !prev);
            return;
        }

        if (_value === 'shift') {
            input.focus();
            setUppercase(prev => !prev);
            return;
        }

        if (_value === 'enter') {
            input.setSelectionRange(0, input.value.length);
            onSubmit?.(_inputRef, input.value);
            return;
        }

        input.focus();
        if (_value === 'backspace') {
            const startPosition = input.selectionStart;
            const endPosition = input.selectionEnd;
            if (startPosition === null || endPosition === null) return;
            if (startPosition === 0 && endPosition === 0) return;
            if (startPosition === endPosition) {
                const newValue = input.value.slice(0, startPosition - 1) + input.value.slice(endPosition);
                input.value = newValue;
                input.dispatchEvent(new Event('input', { bubbles: true }));
                input.setSelectionRange(startPosition - 1, startPosition - 1);
                onChange?.(newValue);
                return;
            }
            const newValue = input.value.slice(0, startPosition) + input.value.slice(endPosition);
            input.value = newValue;
            input.dispatchEvent(new Event('input', { bubbles: true }));
            input.setSelectionRange(startPosition, startPosition);
            onChange?.(newValue);
            return;
        }

        const startPosition = input.selectionStart;
        const endPosition = input.selectionEnd;

        const currentValue = input.value;
        if (startPosition === null || endPosition === null) return;
        const newValue = currentValue.slice(0, startPosition) + _value + currentValue.slice(endPosition);
        input.value = newValue;
        input.dispatchEvent(new Event('input', { bubbles: true }));

        // Move the cursor to the modified position
        const modifiedPosition = startPosition + _value.length;
        input.setSelectionRange(modifiedPosition, modifiedPosition);
        if (lengthToEnter) {
            if (newValue.length >= lengthToEnter) {
                onSubmit?.(_inputRef, newValue);
            }
        }
        onChange?.(newValue);
    }, [_inputRef, lengthToEnter, onChange, onSubmit]);


    const numberButtons = ['1', '2', '3', '4', '5', '6', '7', '8', '9', 'backspace', '0', 'enter'];

    const qwertyKeyboard = [
        ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'backspace'],
        ['empty', 'q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p', 'empty'],
        ['empty', 'a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l', 'empty'],
        ['shift', 'z', 'x', 'c', 'v', 'b', 'n', 'm', '.', 'shift'],
        ['123', ' ', 'enter']];
    const upercaseQwertyKeyboard = [
        ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', 'backspace'],
        ['empty', 'Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'I', 'O', 'P', 'empty'],
        ['empty', 'A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L', 'empty'],
        ['shift', 'Z', 'X', 'C', 'V', 'B', 'N', 'M', '.', 'shift'],
        ['123', ' ', 'enter']
    ];
    const numericalKeyboard = [['1', '2', '3'], ['4', '5', '6'], ['7', '8', '9'], ['backspace', '0', "empty"], ['abc', " ", 'enter']];


    return (
        <div id={styles.Keyboard} >
            {/* {inputRef.current?.value} */}
            {type === "numeric" ?
                <div className={styles.container}>
                    {numberButtons.map((buttonValue) => (
                        <div
                            key={buttonValue}
                            onMouseUp={() => _inputRef?.current?.focus()}
                            onMouseDown={() => onPressKeyHandle(buttonValue)}
                            className={buttonValue === 'enter' ? styles.buttonEnter : buttonValue === 'backspace' ? styles.buttonClear : styles.button}
                        >
                            <p>{buttonValue === "backspace" ? <Icon>backspace</Icon> : buttonValue}</p>
                        </div>
                    ))}
                </div>
                :
                !numerical ? <div className={styles.querty}>
                    {(uppercase ? upercaseQwertyKeyboard : qwertyKeyboard).map((line, index) => (
                        <div key={index} className={styles.line}>
                            {line.map((key, index) => (
                                key === 'empty' ?
                                    <div key={index} className={styles.empty}></div>
                                    : <div
                                        key={index}
                                        onMouseUp={() => _inputRef?.current?.focus()}
                                        onMouseDown={() => onPressKeyHandle(key)}
                                        className={key === 'enter' ? styles.buttonEnter : key === " " ? styles.buttonSpace : key === 'shift' ? styles.buttonShift : key === 'backspace' ? styles.buttonClear : styles.button}
                                    >
                                        {key === " " ? <Icon fontSize='small'>space_bar</Icon> :
                                            key === "backspace" ? <Icon fontSize='small'>backspace</Icon> :
                                                key === "shift" ? <Icon fontSize='small'>arrow_upward</Icon> :
                                                    key === "enter" ? (enterLabel ?? "enter") :
                                                        key} </div>
                            ))}
                        </div>
                    ))}
                </div>
                    :
                    <div className={styles.querty}>
                        {numericalKeyboard.map((line, index) => (
                            <div key={index} className={styles.line}>
                                {line.map((key, index) => (
                                    key === 'empty' ?
                                        <div key={index} className={styles.empty}></div>
                                        : <div
                                            key={index}
                                            onMouseUp={() => _inputRef?.current?.focus()}
                                            onMouseDown={() => onPressKeyHandle(key)}
                                            className={key === 'enter' ? styles.buttonEnter : key === " " ? styles.buttonSpace : key === 'shift' ? styles.buttonShift : key === 'backspace' ? styles.buttonClear : styles.button}
                                        >
                                            <p>
                                                {key === " " ? <Icon fontSize='small'>space_bar</Icon> :
                                                    key === "backspace" ? <Icon fontSize='small'>backspace</Icon> :
                                                        key === "shift" ? <Icon fontSize='small'>arrow_upward</Icon> :
                                                            key === "enter" ? (enterLabel ?? "enter") :
                                                                key
                                                }
                                            </p>
                                        </div>
                                ))}
                            </div>
                        ))}
                    </div>
            }


        </div>
    )
}

export default Keyboard