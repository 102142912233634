import React, { FC, useEffect } from 'react'
import { useNavigate, useRoutes } from 'react-router-dom'
import Toolbar from '../../components/ui/toolbar/Toolbar'
import OperatorAndOrderPadForm from './components/operatorAndOrderPadForm/OperatorAndOrderPadForm'
import styles from './OperatorPage.module.scss'
import UseOperatorPage from './UseOperatorPage'


export interface IOperatorPageProps {
    //propertys
}
const OperatorPage: FC<IOperatorPageProps> = () => {
    const { operators, sharedOrderPadList, onSubmitHandle } = UseOperatorPage();
    return (
        <div id={styles.OperatorPage} >
            <Toolbar></Toolbar>
            <div className={styles.formContainer} >
                <OperatorAndOrderPadForm
                    operators={operators}
                    sharedOrderPads={sharedOrderPadList}
                    onSubmit={onSubmitHandle}
                />
            </div>
        </div>
    )
}
export default OperatorPage