import React from 'react'
import { UiProvider } from './modules/ui/presentation/context/UiContext';
import { BrowserRouter } from 'react-router-dom';
import { HttpProvider } from './modules/api/presentation/HttpContext';
import AppRoutes from './routes/AppRoutes';
import { AuthenticationProvider } from './modules/authentication/presentation/contexts/AuthenticationContext';
import { DeviceProvider } from './modules/device/presentation/context/DeviceContext';
import { CatalogProvider } from './modules/catalog/context/CatalogContext';
import { CartProvider } from './modules/cart/presentation/contexts/CartContext';
import { KeyboardProvider } from './components/_keyboard/KeyboardContext';


const App = () => {
  return (
    <UiProvider>
      <HttpProvider>
        <AuthenticationProvider>
          <DeviceProvider>
            <CartProvider>
              <CatalogProvider>
                <BrowserRouter>
                <KeyboardProvider>
                  <AppRoutes></AppRoutes>
                </KeyboardProvider>
                </BrowserRouter>
              </CatalogProvider>
            </CartProvider>
          </DeviceProvider>
        </AuthenticationProvider>
      </HttpProvider>
    </UiProvider>
  );
}

export default App;
