import React, { FC } from 'react';
import styles from './Empty.module.scss';
import CartEmpty from '../../../assets/icons/cartEmpty.svg'

interface IEmptyProps {
};

export const Empty: FC<IEmptyProps> = ({ }) => {
  return (
    <div id={styles.Empty}>
      <img src={CartEmpty} alt="Ícone carrinho vazio" />
      <h2>O carrinho está vazio!</h2>
    </div>
  );
};