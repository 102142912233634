import { useCallback, useEffect, useState } from 'react';
import { v4 } from 'uuid';
import { useAuthentication } from '../../../modules/authentication/presentation/contexts/AuthenticationContext';
import { IDevice } from '../../../modules/device/interface/Device';
import { useDevice } from '../../../modules/device/presentation/context/DeviceContext';
import { useUi } from '../../../modules/ui/presentation/context/UiContext';
import { CatalogService } from '../../../services/api/catalog/CatalogService';
import CatalogLocalStorage from '../../../services/localStorage/catalog/CatalogLocalStorage';
import GetCatalogUseCase from '../application/useCases/GetCatalogUseCase';
import GetLocalCatalogUseCase from '../application/useCases/GetLocalCatalogUseCase';
import { ICatalog } from '../domain/models/ICatalog';
import { IProduct } from '../domain/models/IProduct';

const __DEV__ = true;

const UseCatalogContext = () => {
  const [catalog, setCatalog] = useState<ICatalog>();
  const [loading, setLoading] = useState(false);
  const { authentication } = useAuthentication();
  const { showLoading, hideLoading } = useUi();

  const getLocalCatalog = useCallback(async () => {
    // if (device) {
    try {
      setLoading(true);
      showLoading('@catalog-local', 'Obtendo catalogo local');
      const catalogRepository = CatalogLocalStorage();
      const response = await GetLocalCatalogUseCase(catalogRepository);
      __DEV__ && console.log('UPDATE CATALOG FROM DB');
      if(response){
        setCatalog(response);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      hideLoading('@catalog-local');
    }
    // }
  }, [hideLoading, showLoading]);

  const getCatalog = useCallback(async () => {
    try {
      // showLoading('@catalog-DB', 'Obtendo catalogo local');
      setLoading(true);
      const catalogService = CatalogService();
      const catalogRepository = CatalogLocalStorage();
      const response = await GetCatalogUseCase(catalogService, catalogRepository);
      __DEV__ && console.log('UPDATE CATALOG FROM SERVER');
      console.log('catalogaWallpaper SERVER', response.wallpaperUrl, response.videoUrl);
      setCatalog(response);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      // hideLoading('@catalog-DB');
    }
  }, []);

  useEffect(() => {
    if (authentication) {
      getCatalog();
    }
  }, [authentication, getCatalog]);

  useEffect(() => {
    getLocalCatalog();
  }, [getLocalCatalog]);

  const getProduct = useCallback(
    (productId: string) => {
      const product = catalog?.stores
        .flatMap(item => item.seccionedProduct)
        .flatMap(item => item.products)
        .find(item => item.id === productId);
      return product;
    },
    [catalog],
  );

  const getCategory = useCallback(
    (categoryId: string) => {
      const category = catalog?.stores.flatMap(item => item.seccionedProduct).find(item => item.id === categoryId);
      return category;
    },
    [catalog],
  );

  const getStore = useCallback(
    (storeId: string) => {
      const store = catalog?.stores.find(item => item.id === storeId);
      return store;
    },
    [catalog],
  );

  return {
    catalog,
    loading,
    getProduct,
    getCategory,
    getStore,
  };
};

export default UseCatalogContext;
