
import { Icon, IconButton, Radio } from '@mui/material';
import React, { FC, useCallback, useEffect, useState } from 'react'
import { IProductComposition } from '../../../../../../modules/catalog/domain/models/IProductComposition';
import { IProductCompositionItem } from '../../../../../../modules/catalog/domain/models/IProductCompositionItem';
import { useComposition } from '../../context/CompositionContext';
import { ITotalsComposition } from '../../interfaces/ITotalsComposition';
import styles from './BoxProductCompositionItem.module.scss'
export interface IProductCompositionItemProps {
    //propertys
    productCompositionItem: IProductCompositionItem;
    productComposition: IProductComposition;
    disabledAllAdd?: boolean
}
const BoxProductCompositionItem: FC<IProductCompositionItemProps> = ({ productCompositionItem, productComposition, disabledAllAdd }) => {
    const {
        getTotalsCompositionItem,
        orderCompositions,
        addCompositionItem,
        removerCompositionItem,
        totals,
        disabled
    } = useComposition();
    const [totalsItem, setTotalsItems] = useState<ITotalsComposition>({ price: 0, quantity: 0 });

    const [disabledAdd, setDisabledAdd] = useState(false)
    const [disabledRemove, setDisabledRemove] = useState(false)
    const [disabledCheckBox, setDisabledCheckBox] = useState(false)

    useEffect(() => {
        const newTotals = getTotalsCompositionItem(productCompositionItem.id);
        setTotalsItems(newTotals);
    }, [orderCompositions, productCompositionItem]);

    useEffect(() => {
        const totalCompositionItems = getTotalsCompositionItem(productCompositionItem.id);
        const maxCompositionItem = !!productCompositionItem.max && (totalCompositionItems.quantity >= productCompositionItem.max)

        if (disabledAllAdd || maxCompositionItem) {
            setDisabledAdd(true);
        } else {
            setDisabledAdd(false)
        }

    }, [disabledAllAdd, productCompositionItem, orderCompositions])


    const removeQuantityHandle = useCallback(
        () => {
            removerCompositionItem(productComposition.id, productCompositionItem, 1);
        },
        [removerCompositionItem, productComposition.id, productCompositionItem],
    )

    const addQuantityHandle = useCallback(
        () => {
            addCompositionItem(productComposition, productCompositionItem, 1);
        },
        [addCompositionItem, productComposition, productCompositionItem],
    )

    const onClickRadio = useCallback(
        () => {
            if (!!totalsItem.quantity) {
                removeQuantityHandle()
            } else {
                addQuantityHandle()
            }
        },
        [totalsItem],
    )


    return (
        <div id={styles.ProductCompositionItem} >
            <div className={styles.container} >
                <div className={styles.start}>
                    <div className={styles.description}>{productCompositionItem.description}
                        {!!productCompositionItem.price && <b>({new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(productCompositionItem.price)})</b>}
                    </div>
                </div>
                <div className={styles.end}>

                    <div className={styles.totalPriceContainer}>
                        {!!totalsItem.price && <div className={styles.totalPrice}>+{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalsItem.price)}</div>}
                    </div>

                    {
                        !disabled && (
                            (productComposition.min === 1 && productComposition.max === 1) ?
                                < div className={styles.selector}>
                                    <Radio checked={!!totalsItem.quantity} onClick={onClickRadio}></Radio>
                                </div>

                                :
                                // (
                                //     productCompositionItem.max === 1 ?
                                //         <div className={styles.selector}>
                                //             <Checkbox
                                //                 disabled={!totalsItem.quantity && disabledCheckBox}
                                //                 checked={!!totalsItem.quantity}
                                //                 onClick={onClickRadio}
                                //             ></Checkbox>
                                //         </div>
                                //         :
                                <div className={styles.quantitiy}>
                                    <IconButton
                                        disabled={!totalsItem.quantity}
                                        className={!totalsItem.quantity ? styles.disabledIcon : styles.buttonIcons}
                                        onClick={removeQuantityHandle}><Icon>remove</Icon></IconButton>
                                    {totalsItem.quantity}
                                    <IconButton
                                        disabled={disabledAdd}
                                        className={disabledAdd ? styles.disabledIcon : styles.buttonIcons}
                                        onClick={addQuantityHandle}
                                    ><Icon>add</Icon></IconButton>
                                </div>)
                        // )
                    }
                </div>
            </div>
        </div >
    )
}
export default BoxProductCompositionItem