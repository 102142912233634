import { Alert, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material'
import React, { ChangeEvent, FC, FormEvent, useCallback, useMemo, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import Input from '../../components/ui/input/Input'
import Toolbar from '../../components/ui/toolbar/Toolbar'
import { useAuthentication } from '../../modules/authentication/presentation/contexts/AuthenticationContext'
import styles from './FirstOpenPage.module.scss'
import ArenaXPLogo from '../../assets/arenalogo.png';
import ArenaXPImage from '../../assets/arenaxp.png'


export interface IFirstOpenPageProps {
    //propertys
}
const FirstOpenPage: FC<IFirstOpenPageProps> = () => {
    const { saveMacAddress } = useAuthentication();
    const [openAlert, setopenAlert] = useState(false);
    const [values, setValues] = useState({ macAddress: "" })
    const navigate = useNavigate()

    const { authentication } = useAuthentication();

    const submitHandle = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        setopenAlert(true);
    }, [])

    const changeHandle = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        setValues((prev) => ({ ...prev, [ev.target.name]: ev.target.value }));
    }, []);

    const onConfirmValues = useCallback(
        () => {
            saveMacAddress(values.macAddress);
            navigate("/", { replace: true })
        },
        [navigate, saveMacAddress, values.macAddress],
    )


    const image = useMemo(() => authentication?.local?.id === '023dc92c-2386-9a5a-e01e-28ae3894c189' ? ArenaXPImage : "/assets/img/LogoMeep.svg", [authentication?.local])

    return (
        <div id={styles.FirstOpenPage} >
            <form onSubmit={submitHandle}>

                <div className={styles.container} >
                    <div className={styles.toolbar} >
                        <Toolbar logoUrl={ArenaXPLogo} />
                    </div>
                    <div className={styles.containerMac}>

                        <div className={styles.content}>
                            <img className={styles.imageLogo} src={image} alt='Logo Arena XP' />
                            <p>Preencha o MAC Address cadastrado no <b>Portal Meep</b></p>
                            <Input required name='macAddress' onChange={changeHandle} label={'Insira o mac-address/IMEI'} />

                            <Button variant='contained' type='submit'>
                                Confirmar
                            </Button>
                        </div>
                    </div>
                </div>
            </form>



            <Dialog
                open={openAlert}
                onClose={() => setopenAlert(false)}
                aria-describedby="alert-dialog-slide-description"
                className={styles.containerDialog}
            >
                <div>
                    <DialogTitle>{"MAC-ADDRESS/IMEI"}</DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            <div>
                                <p>Você deseja confirmar o MAC-Address abaixo?</p>
                            </div>
                            <div>
                                <b>{values.macAddress}</b>
                            </div>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <div className={styles.buttons}>
                            <Button className={styles.cancel} fullWidth variant="outlined" onClick={() => setopenAlert(false)}>Cancelar</Button>
                            <Button className={styles.confirm} fullWidth onClick={onConfirmValues}>Confirmar</Button>
                        </div>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    )
}
export default FirstOpenPage