import {IAuthenticationDTO} from '../../../modules/authentication/domains/dto/AuthenticationDTO';
import {IAuthenticationService} from '../../../modules/authentication/domains/interfaces/IAuthenticationService';
import {LoginApi} from '../bases/Api';
import {IAuthenticationServerResponse} from './IAuthenticationServerResponse';
import {parseAuthenticationServerResponseToAuthentication} from './TransformAuthenticationResponse';
import qs from 'qs';

const AuthenticationService = (): IAuthenticationService => {
  const updateAuthentication = async (mac: string, terminalId: string): Promise<IAuthenticationDTO> => {
    const data = qs.stringify({
      IMEI: `${mac}`,
      usuarioMeepPOSId: terminalId,
    });
    const response = await LoginApi.post<IAuthenticationServerResponse>(
      '/UsuarioMeepPOS/AtualizarDadosEquipamento',
      data,
    );

    return parseAuthenticationServerResponseToAuthentication(response.data);
  };

  const authenticate = async (mac: string): Promise<IAuthenticationDTO> => {
    const data = {
      IMEI: mac,
      tipoDispositivo: '4', //TODO: Verificar tablet
      tipoSistemaOperacional: 1,
    };

    const response = await LoginApi.post<IAuthenticationServerResponse>(
      'UsuarioMeepPOS/LoginAutomaticoPOS',
      qs.stringify(data),
    );

    return parseAuthenticationServerResponseToAuthentication(response.data);
  };

  return {
    updateAuthentication,
    authenticate,
  };
};

export default AuthenticationService;
