import { IOrderComposition } from "../../../../../modules/cart/domain/models/IOrderComposition"
import { IProductComposition } from "../../../../../modules/catalog/domain/models/IProductComposition"


export const CalcTotalsOrderComposition = (prevOrderCompositions: IOrderComposition[], productComposition: IProductComposition): { quantity: number, price: number } => {

    const calcTotalsOrderComposition = () => {
        const orderComposition = prevOrderCompositions.find(prevOrderComposition => prevOrderComposition.id === productComposition.id)
        if (orderComposition) {
            const price = orderComposition.compositionItems.map(_item => (_item.totalPrice)).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
            const quantity = orderComposition.compositionItems.map(_item => (_item.quantity)).reduce((previousValue, currentValue) => previousValue + currentValue, 0)
            return { quantity, price }
        } else {
            return { quantity: 0, price: 0 }
        }
    }

    return (
        calcTotalsOrderComposition()
    )
}
