import React, { FC } from 'react'
import { Route, Routes } from 'react-router-dom'
import CatalogPage from '../pages/catalog/CatalogPage'
import FirstOpenPage from '../pages/firstOpen/FirstOpenPage'
import HomePage from '../pages/home/HomePage'
import OperatorPage from '../pages/operator/OperatorPage'

export interface I_RoutesProps {
  //propertys
}
const AppRoutes: FC<I_RoutesProps> = () => {
  return (
    <Routes>
      <Route path={"/"} >
        {/* <Route path={"/"} element={<HomePage />} /> */}
        <Route path={"/"} element={<OperatorPage />} />
        <Route path={"/catalog/:operatorId/:tag/:number"} element={<CatalogPage />} />
        <Route path={"/catalog/:operatorId/:tag/"} element={<CatalogPage />} />
        <Route path={"/firstOpen"} element={<FirstOpenPage />} />
      </Route>
    </Routes>
  )
}
export default AppRoutes