import {useCallback, useEffect, useState} from 'react';
import {IUseCartContext} from '../../domain/interfaces/IUseCaseCart';
import {ICartItem} from '../../domain/models/ICart';
import {AddCartItemByProductUseCase} from '../../application/useCases/AddCartItemByProductUseCase';
import {CalcTotalsCartUseCase} from '../../application/useCases/CalcTotalsCartUseCase';
import {RemoveCartItemByIdUseCase} from '../../application/useCases/RemoveCartItemByIdUseCase';
import {IOrderComposition} from '../../domain/models/IOrderComposition';
import {IProductCart} from '../../domain/models/IProductCart';
import {AddCartItemByIdUseCase} from '../../application/useCases/AddCartItemByIdUseCase';
import {RemoveCartItemByProductIdUseCase} from '../../application/useCases/RemoveCartItemByProductIdUseCase';

const useCaseCart = (): IUseCartContext => {
  const [cartItems, setCartItems] = useState<ICartItem[]>([]);
  const [totals, setTotals] = useState<{
    totalQuantity: number;
    totalPrice: number;
  }>({totalQuantity: 0, totalPrice: 0});

  /**
   * Adicionar produto no carrinho caso ja exista adiciona na quantidade
   */
  const addProductCart = useCallback(
    (product: IProductCart, quantity: number, orderCompositions?: IOrderComposition[]) => {
      setCartItems(prev => AddCartItemByProductUseCase(prev, product, quantity, orderCompositions));
    },
    [],
  );

  const removeCartItemByProductId = useCallback((productId: string, quantity: number) => {
    setCartItems(prev => RemoveCartItemByProductIdUseCase(prev, productId, quantity));
  }, []);

  /**
   * Remove quantidade de items do carrinho
   */
  const removeCartItem = useCallback((cartItemId: string, quantity: number) => {
    setCartItems(prev => RemoveCartItemByIdUseCase(prev, cartItemId, quantity));
  }, []);

  const addCartItem = useCallback((cartItemId: string, quantity: number) => {
    setCartItems(prev => AddCartItemByIdUseCase(prev, cartItemId, quantity));
  }, []);

  /**
   * Limpar carrinho
   */
  const clearCart = useCallback(() => {
    setCartItems([]);
  }, []);

  useEffect(() => {
    setTotals(CalcTotalsCartUseCase(cartItems));
  }, [cartItems]);

  return {
    cartItems,
    totals,
    addProductCart,
    removeCartItem,
    addCartItem,
    removeCartItemByProductId,
    clearCart,
  };
};

export default useCaseCart;
