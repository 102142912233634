import React, { createContext, FC, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { Api } from '../../../../services/api/bases/Api';
import DeviceService from '../../../../services/api/device/DeviceService';
import DeviceLocalStorage from '../../../../services/localStorage/device/DeviceLocalStorage';
import { useAuthentication } from '../../../authentication/presentation/contexts/AuthenticationContext';
import { useUi } from '../../../ui/presentation/context/UiContext';
import GetDeviceUseCase from '../../application/GetDeviceUseCase';
import GetLocalDeviceUseCase from '../../application/GetLocalDeviceUseCase';
import { SystemType } from '../../domain/enums/SystemType';
import { IDevice } from '../../interface/Device';

interface IDeviceContext {
  device?: IDevice;
  isSystemType: (systemType: SystemType) => boolean;
}

const __DEV__ = true;

const DeviceContext = createContext<IDeviceContext>({} as IDeviceContext);

export const DeviceProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [device, setDevice] = useState<IDevice>();
  const { authentication, macAddress, isAuth, hasToken } = useAuthentication();

  const { showLoading, hideLoading } = useUi();

  const getLocalDevice = useCallback(async () => {
    const deviceRepository = DeviceLocalStorage();
    try {
      const response = await GetLocalDeviceUseCase(deviceRepository);
      if (response) {
        setDevice(response);
        __DEV__ && console.log('UPDATE DEVICE FROM SERVER', response.deviceName);
      } else {
        throw (new Error("dados de equipamento não encotrados"))
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  const getDevice = useCallback(async (_macAddress: string, _authentication) => {
    const deviceApi = DeviceService();
    const deviceRepository = DeviceLocalStorage();
    try {
      const response = await GetDeviceUseCase(deviceApi, deviceRepository, _authentication.id, _macAddress);
      setDevice(response);
      __DEV__ && console.log('UPDATE DEVICE FROM SERVER', response.deviceName);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    if (macAddress && authentication && !authentication.fromStorage) {
      console.log(authentication)
      getDevice(macAddress, authentication);
    }

  }, [macAddress, authentication, getDevice]);

  useEffect(() => {
    getLocalDevice();
    return () => { };
  }, [getLocalDevice, macAddress]);

  const isSystemType = useCallback(
    (_systemType: SystemType) => {
      return !!device?.configSymstem.find(item => item.systemType === _systemType);
    },
    [device],
  );

  return (
    <DeviceContext.Provider value={{ device, isSystemType }}>
      <>{children}</>
    </DeviceContext.Provider>
  );
};

export const useDevice = () => {
  const context = useContext(DeviceContext);
  return context;
};
