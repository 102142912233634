import React, { createContext, FC, ReactNode, useContext } from 'react';
import { IUseCartContext } from '../../domain/interfaces/IUseCaseCart';
import useCaseCart from './_UseCartContext';

const CartContext = createContext<IUseCartContext>({} as IUseCartContext);

export const CartProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const caseCart = useCaseCart();
  return <CartContext.Provider value={caseCart}>{children}</CartContext.Provider>;
};

export const CartConsumer = CartContext.Consumer;

export const useCart = () => {
  const context = useContext(CartContext);
  return context;
};
