import {IDeviceRepository} from '../domain/interfaces/IDeviceRepository';
import {IDeviceService} from '../domain/interfaces/IDeviceService';

const GetDeviceUseCase = async (
  deviceService: IDeviceService,
  deviceRepository: IDeviceRepository,
  deviceId: string,
  macAddress: string,
) => {
  const response = await deviceService.getDevice(deviceId, macAddress);
  deviceRepository.setDevice(response);
  return response;
};
export default GetDeviceUseCase;
