import { useCallback, useEffect, useState } from "react"
import { IOrderComposition } from "../../../../../modules/cart/domain/models/IOrderComposition"
import { IProductComposition } from "../../../../../modules/catalog/domain/models/IProductComposition"
import { IProductCompositionItem } from "../../../../../modules/catalog/domain/models/IProductCompositionItem"
import { IProductCompositionContext } from "../interfaces/IProductCompositionContext"
import { AddOrderCompositionByProductCompositionItem } from "../useCases/AddOrderCompositionByProductCompositionItem"
import { CalcTotalsAllCompositions } from "../useCases/CalcTotalsAllCompositions"
import { CalcTotalsOrderComposition } from "../useCases/CalcTotalsComposition"
import { CalcTotalsOfCompositionItemByCompositionId } from "../useCases/CalcTotalsOfCompositionItemByCompositionId"
import { RemoveOrderCompositionByCompositionItem } from "../useCases/RemoveOrderCompositionByCompositionItem"
import { ValidateComposition } from "../useCases/ValidateCompositions"

const useCompositionsContext = (productCompositions: IProductComposition[]): IProductCompositionContext => {

    const [orderCompositions, setOrderComposition] = useState<IOrderComposition[]>([]);
    const [unValidatedCompositions, setUnValidatedCompositions] = useState<IProductComposition[]>([])
    const [totals, setTotals] = useState<{ quantity: number, price: number }>({ quantity: 0, price: 0 });

    const addCompositionItem = useCallback(
        (productComposition: IProductComposition, productCompositionItem: IProductCompositionItem, quantity: number = 1) => {
            setOrderComposition(prev => AddOrderCompositionByProductCompositionItem(prev, productComposition, productCompositionItem, quantity))
        },
        [],
    )
    const removerCompositionItem = useCallback(
        (productCompositionId: string, productCompositionItem: IProductCompositionItem, quantity: number = 1) => {
            setOrderComposition(prev => RemoveOrderCompositionByCompositionItem(prev, productCompositionId, productCompositionItem, quantity))
        },
        [],
    )
    const getTotalsCompositionItem = useCallback(
        (productCompositionItemId: string) => {
            return CalcTotalsOfCompositionItemByCompositionId(orderCompositions, productCompositionItemId);
        },
        [orderCompositions],
    )
    const getTotalsComposition = useCallback(
        (productComposition: IProductComposition) => {
            return CalcTotalsOrderComposition(orderCompositions, productComposition);
        },
        [orderCompositions],
    )

    useEffect(
        () => {
            const validateComposition = ValidateComposition();
            setUnValidatedCompositions(validateComposition.getNotValidateCompositonsByProductComposition(orderCompositions, productCompositions));
        },
        [orderCompositions, productCompositions],
    )

    useEffect(() => {
        setTotals(CalcTotalsAllCompositions(orderCompositions));
    },
        [orderCompositions],
    )

    return (
        {
            orderCompositions,
            totals,
            addCompositionItem,
            removerCompositionItem,
            getTotalsCompositionItem,
            getTotalsComposition,
            unValidatedCompositions
        }
    )
}

export default useCompositionsContext

