import { Icon } from '@mui/material'
import React, { FC, useCallback } from 'react'
import styles from './Toolbar.module.scss'
export interface IToolbarProps {
    //propertys
    logoUrl?: string
    onClickExit?: () => void;
    onClickRfresh?: () => void;
    onClickBack?: () => void;
    refreshButton?: boolean

}
const Toolbar: FC<IToolbarProps> = ({ logoUrl, onClickExit, onClickBack, onClickRfresh, refreshButton = true }) => {

    const refreshHandle = useCallback(
        () => {
            window.location.reload()
        },
        [],
    )

    return (
        <div id={styles.Toolbar} >
            <div className={styles.container} >
                <div className={styles.start}>
                    {!!onClickBack &&
                        <div className={styles.button} onClick={onClickBack}><Icon>arrow_back</Icon>Voltar</div>
                    }
                </div>
                <div className={styles.center}>{logoUrl && <img src={logoUrl}></img>}</div>
                <div className={styles.end}>
                    {!!onClickRfresh || refreshButton &&
                        <div className={styles.button} onClick={onClickRfresh ?? refreshHandle}><Icon>refresh</Icon></div>
                    }
                    {onClickExit &&
                        <div className={styles.button} onClick={onClickExit}><Icon>logout</Icon>Sair</div>
                    }
                </div>
            </div>
        </div>
    )
}
export default Toolbar