
import React, { FC, useMemo } from 'react'
import Toolbar from '../../components/ui/toolbar/Toolbar'
import { useCart } from '../../modules/cart/presentation/contexts/CartContext'
import { useCatalog } from '../../modules/catalog/context/CatalogContext'
import styles from './CatalogPage.module.scss'
import CartView from './components/cartView/CartView'
import CatalogList from './components/catalogList/CatalogList'
import ExtractResume from './components/extracResume/ExtractResume'
import UseCatalogPage from './UseCatalogPage'
import ArenaXPImage from '../../assets/arenalogo.png';
import { useAuthentication } from '../../modules/authentication/presentation/contexts/AuthenticationContext'
export interface ICatalogPageProps {
    //propertys
}
const CatalogPage: FC<ICatalogPageProps> = () => {
    const { extract, orderPad, tag, number, onLoadingExtract, onClickBackHandle, onClickSendButtonHandle } = UseCatalogPage();
    const { cartItems } = useCart();

    const { authentication } = useAuthentication();

    // const image = useMemo(() => authentication?.local?.id === '023dc92c-2386-9a5a-e01e-28ae3894c189' ? ArenaXPImage : "/assets/img/LogoMeep.svg", [authentication?.local])
    const image = useMemo(() => authentication?.local?.id === '023dc92c-2386-9a5a-e01e-28ae3894c189' ? ArenaXPImage : "/assets/img/LogoMeep.svg", [authentication?.local])

    return (
        <div id={styles.CatalogPage} >
            <div className={styles.toolbar} >
                <Toolbar logoUrl={image} onClickBack={onClickBackHandle} />
            </div>
            <div className={styles.container} >
                <div className={styles.catalogListContainer} >
                    <CatalogList />
                </div>
                <div className={styles.cartContainer} >
                    <ExtractResume onLoading={onLoadingExtract} number={number} tag={tag} orderPad={orderPad} extract={extract} onClickReadTag={onClickBackHandle} />
                    <CartView disabledSendButton={!extract || !cartItems.length} onClickSendButton={onClickSendButtonHandle} />
                </div>
            </div>
        </div>
    )
}
export default CatalogPage