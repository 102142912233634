import { IProductComposition } from "../../../../../modules/catalog/domain/models/IProductComposition"


export const calcPricFreeQuantity = (price: number, quantity: number, freeQuantity?: number) => {
    if (freeQuantity) {
        if ((quantity - freeQuantity) > 0) {
            return (quantity - freeQuantity) * price
        } else {
            return 0
        }
    } else {
        return quantity * price
    }
}

export const getProductCompositionOfCompositionItemId = (productCompositions: IProductComposition[], compositionItemId: string) => {
    const composition = productCompositions.find((_productComposition) => {
        return _productComposition.compositionItems.find((_compositionItem) => _compositionItem.id === compositionItemId);
    })
    if (!composition) {
        console.error("composition Id not found");
    }
    return composition as IProductComposition;
}