import {ICatalogApi} from '../../domain/interfaces/ICatalogApi';
import {ICatalogRepository} from '../../domain/interfaces/ICatalogRepository';
import {ICatalog} from '../../domain/models/ICatalog';

const GetCatalogUseCase = async (
  catalogService: ICatalogApi,
  catalogRepository: ICatalogRepository,
): Promise<ICatalog> => {
  const response = await catalogService.get();
  console.log('[CATALOG]', response);
  catalogRepository.setCatalog(response);
  return response;
};
export default GetCatalogUseCase;
