import { IOperatorDTO } from '../../../modules/operator/domains/dto/OperatorDTO';
import { IOperatorServerResponse } from './IOperatorServerResponse';

const __DEV__ = true;

export const parseOperatorsServerResponseToLocalOperator = (response: IOperatorServerResponse[]): IOperatorDTO[] => {
  const operators: IOperatorDTO[] = response.map(it => ({
    id: it.id,
    name: it.nome,
    papers: it.papeis,
    password: it.senha,
    useTagPassword: it.usarCartaoCashlessComoSenha,
    tag: it.tag,
    isRoot: it.acessoPrivilegiado,
  }));

  __DEV__ && console.log('[OPERATOR_SERVICE][PARSE]', { operators });

  return operators;
};
