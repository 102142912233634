import React, {FC, useCallback} from 'react';
import LocalPrinterApi from '../../../services/api/localPrinter/LocalPrinterApi';
import { IOrder } from '../../cart/domain/models/IOrder';
import {useDevice} from '../../device/presentation/context/DeviceContext';
import {useUi} from '../../ui/presentation/context/UiContext';
import SendToLocalPrinterUseCase from '../application/SendToLocalPrinterUseCase';
const UseLocalPrinter = () => {
  const {showLoading, hideLoading, toast} = useUi();
  const {device} = useDevice();

  const sendOrderToLocalPrint = useCallback(
    (order: IOrder, tagOrNumber: string) => {
      try {
        if (device) {
          showLoading('@localPrinterSend',  'Pedido sendo enviado para cozinha');
          const serviceLocalPrinter = LocalPrinterApi();
          SendToLocalPrinterUseCase(
            serviceLocalPrinter,
            tagOrNumber,
            device?.deviceName,
            order,
            device?.localServerAddress,
          );
        } else {
          toast('Device não registrado');
        }
      } catch {
        toast('Falha ao enviar para alguma das impressoras informe um atendente', 'error');
      } finally {
        hideLoading('@localPrinterSend');
      }
    },
    [device, hideLoading, showLoading, toast],
  );

  return {sendOrderToLocalPrint};
};

export default UseLocalPrinter;
