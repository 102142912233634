import { IAuthenticationModel } from '../../../modules/authentication/domains/models/Authentication';
import { IAuthenticationRepository } from '../../../modules/authentication/domains/interfaces/IAuthenticationRepository';
import localforage from 'localforage';

export const AuthenticationRepository = (): IAuthenticationRepository => {
  const key = '@MEEPPOS:AUTHENTICATION';

  const get = async (): Promise<IAuthenticationModel | null> => {
    const response = await localforage.getItem<IAuthenticationModel>(key);
    console.log("GET LOCAL STORAGE AUTH", response)
    return response ? { ...response, fromStorage: true } : null;
  };

  const save = async (data: IAuthenticationModel): Promise<void> => {
    await localforage.setItem(key, data);
    return;
  };

  const erase = async (): Promise<void> => {
    await localforage.removeItem(key);
  };

  const setMacAddress = async (value: string): Promise<void> => {
    await localforage.setItem('@mac', value);
    return;
  };
  const getMacAddress = async (): Promise<string | null> => {
    const response = await localforage.getItem<string>('@mac');
    return response;
  };

  return {
    get,
    save,
    erase,
    setMacAddress,
    getMacAddress,
  };
};
