import {ICartItem} from '../../domain/models/ICart';

// export const addProductCartUseCase = (): IaddCartItemUseCase => {
export const AddCartItemByIdUseCase = (
  prevCartItems: ICartItem[],
  cartItemId: string,
  quantity: number,
): ICartItem[] => {
  const addCartItem = (
    _prevCartItems: ICartItem[],
    _cartItemId: string,
    _quantity: number,
  ) => {
    const newCartItemsadddQuantitys = _prevCartItems.map(prevCartItem => {
      if (prevCartItem.id === _cartItemId) {
        return {...prevCartItem, quantity: prevCartItem.quantity + _quantity};
      } else {
        return {...prevCartItem};
      }
    });
    return newCartItemsadddQuantitys;
  };

  return addCartItem(prevCartItems, cartItemId, quantity);
};
