import {IAuthenticationRepository} from '../../domains/interfaces/IAuthenticationRepository';
import {IAuthentication} from '../../presentation/interface/IAuthentication';

/**
 * @name checkAuthentication
 * @description check on local storage if exists some instance of authentication, return it if exist, make a call on server if it not exist
 * @param authenticationRepository {IAuthenticationRepository} - repository to look up for authentication data
 * @param authenticationService {IAuthenticationService} - service to retrieve the authentication data
 * @param imei {string} - imei code for login
 * @param mac {string} - mac code for login
 * @param acquirer {AcquirerType} - acquirer type to handle configs from server
 * @param serial {string} - machine serial number for authentication
 */
export const CheckLocalAuthenticationUseCase = async (
  authenticationRepository: IAuthenticationRepository,
): Promise<IAuthentication | null> => {
  const response = await authenticationRepository.get();
  return response;
};
