import {IConsumeService} from '../../../domain/interfaces/IConsumeService';
import {ISharedOrderPadRepository} from '../../../domain/interfaces/sharedOrderPad/ISharedOrderPadRepository';
import {ISharedOrderPad} from '../../../domain/models/SharedOrderPad';

const GetSharedOrderPadListUseCase = async (
  consumeServer: IConsumeService,
  sharedOrderPadRepository: ISharedOrderPadRepository,
  localId: string,
): Promise<ISharedOrderPad[]> => {
  const response = await consumeServer.getSharedOrderPads(localId);
  sharedOrderPadRepository.saveSharedOrderPads(response);
  return response;
};
export default GetSharedOrderPadListUseCase;
