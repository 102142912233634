import {IAuthenticationRepository} from '../../domains/interfaces/IAuthenticationRepository';

const SetMacAddressUseCase = async (
  authenticationRepository: IAuthenticationRepository,
  macAddress: string,
) => {
  authenticationRepository.setMacAddress(macAddress);
  return {};
};
export default SetMacAddressUseCase;
