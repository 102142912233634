import {IOperator} from '../../domains/models/Operator';

const ValidateOperatorUseCase = (operators: IOperator[], operatorId: string, password: string) => {
  const operator = operators.find(item => item.id === operatorId);
  if (operator?.password === password) {
    return true;
  } else {
    return false;
  }
};
export default ValidateOperatorUseCase;
