import {IDeviceResponse} from '../../../modules/device/domain/dtos/DeviceResponse';
import {IDeviceService} from '../../../modules/device/domain/interfaces/IDeviceService';
import AnticorruptionDeviceService from './anticorruption/AnticorruptionDeviceService';

const DeviceService = (): IDeviceService => {
  const getDeviceParams = async (deviceId: string, macAddress: string): Promise<IDeviceResponse> => {
    const response = await AnticorruptionDeviceService().getDeviceConfig(deviceId, macAddress);
    return response;
  };

  return {getDevice: getDeviceParams};
};
export default DeviceService;
