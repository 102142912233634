import {IAuthenticationDTO} from '../../../modules/authentication/domains/dto/AuthenticationDTO';
import {IAuthenticationServerResponse} from './IAuthenticationServerResponse';

export const parseAuthenticationServerResponseToAuthentication = (
  response: IAuthenticationServerResponse,
): IAuthenticationDTO => {
  if (!response) {
    throw new Error('Missing authentication server response');
  }

  return {
    id: response.usuarioMeepPOSId,
    name: response.usuarioMeepPOSApelido,
    local: {
      id: response.localId,
      name: response.localNome,
      imageUrl: response.localId, //TODO:verificar Imagem URL
    },
    token: response.token,
  };
};
