
import React from 'react'
import { IOrderComposition } from '../../../../../modules/cart/domain/models/IOrderComposition';
import { IProductCompositionItem } from '../../../../../modules/catalog/domain/models/IProductCompositionItem';
import { calcPricFreeQuantity } from './BaseCompositionUseCase'

export const RemoveOrderCompositionByCompositionItem = (prevOrderComposition: IOrderComposition[], compositionId: string, productCompositionItem: IProductCompositionItem, quantity: number): IOrderComposition[] => {





    const removeOrderCompositionByCompositionItem = (_prevOrderComposition: IOrderComposition[], _compositionId: string, _productCompositionItem: IProductCompositionItem, _quantity: number) => {
        const newOrderCompositions = _prevOrderComposition.map(_composition => {
            if (_composition.id === _compositionId) {
                const newCompositionItems = _composition.compositionItems.map((_compositionItem) => {
                    if (_productCompositionItem.id === _compositionItem.id) {
                        const newQuantity = _compositionItem.quantity - _quantity;
                        return {
                            ..._compositionItem,
                            quantity: newQuantity,
                            totalPrice: calcPricFreeQuantity(_productCompositionItem.price, newQuantity,
                                _productCompositionItem.quantityFree)
                        }
                    } else {
                        return _compositionItem
                    }
                }).filter(_compositionItem => _compositionItem.quantity > 0);

                return { ..._composition, compositionItems: newCompositionItems }
            } else {
                return _composition
            }

        }).filter(_orderComposition => _orderComposition.compositionItems.length > 0);

        return newOrderCompositions
    }

    return (
        removeOrderCompositionByCompositionItem(prevOrderComposition, compositionId, productCompositionItem, quantity)
    )
}
