import localforage from 'localforage';
import { IOperatorRepository } from '../../../modules/operator/domains/interfaces/IOperatorRepository';
import { IOperator } from '../../../modules/operator/domains/models/Operator';

export const OperatorRepository = (): IOperatorRepository => {
  const key = '@MEEPPOS:OPERATOR';

  const getAll = async (): Promise<IOperator[] | null> => {
    const response = await localforage.getItem<IOperator[]>(key);
    return response

  };

  const getById = async (operatorId: string): Promise<IOperator> => {
    const operators = await getAll();
    const response = operators?.find(item => item.id === operatorId) ?? null;
    if (response) {
      return response;
    } else {
      throw new Error('Operator not found');
    }
  };

  const save = async (data: IOperator[]): Promise<void> => {
    await localforage.setItem(key, data);
    return;
  };

  const erase = async (): Promise<void> => {
    await localforage.removeItem(key);
  };



  return {
    getAll,
    getById,
    save,
    erase
  };
};
