import {AxiosResponse} from 'axios';
import {IGetCatalogResponse} from '../../../modules/catalog/domain/dtos/IGetCatalogResponse';
import {
  ILastSyncCatalogResponse,
  ILastSyncCatalogServerResponse,
} from '../../../modules/catalog/domain/dtos/ILastSyncCatalogResponse';
import {ICatalogApi} from '../../../modules/catalog/domain/interfaces/ICatalogApi';
import {Api} from '../bases/Api';

export const CatalogService = (): ICatalogApi => {
  const api = Api();

  const get = async (): Promise<IGetCatalogResponse> => {
    const response = await api.get<any, AxiosResponse<IGetCatalogResponse>>('equipment/productcatalog');
    console.log(response.data);
    
    return response.data;
  };

  const checkUpdate = async (localId: string): Promise<ILastSyncCatalogResponse> => {
    const response = await api.get<any, AxiosResponse<ILastSyncCatalogServerResponse>>(
      'Produto/BuscarUltimaAlteracaoProdutos/' + localId,
    );

    return {lastSyncCatalog: response.data.ultimaAtualizacaoCardapio};
  };

  return {get, checkUpdate};
};
