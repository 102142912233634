import { Icon } from '@mui/material';
import React, { FC, useCallback } from 'react'
import { ICartItem } from '../../../../modules/cart/domain/models/ICart';
import { useCart } from '../../../../modules/cart/presentation/contexts/CartContext';
import styles from './CartView.module.scss'
import Image from '../../../../components/ui/image/Image'
import { Empty } from '../../../../components/ui/empty/Empty';
export interface ICartViewProps {
    //propertys
    disabledSendButton?: boolean;
    onClickSendButton?: (cartItem: ICartItem[]) => void;
}
const CartView: FC<ICartViewProps> = ({ disabledSendButton, onClickSendButton }) => {
    const { cartItems, addCartItem, removeCartItem, clearCart, totals } = useCart();

    const onPressRemoveHandle = useCallback(
        (cartItem: ICartItem) => {
            removeCartItem(cartItem.id, 1)
        },
        [removeCartItem],
    )
    const onPressAddHandle = useCallback(
        (cartItem: ICartItem) => {
            addCartItem(cartItem.id, 1)
        },
        [addCartItem],
    )
    const onClearHandle = useCallback(
        () => {
            clearCart()
        },
        [clearCart],
    )



    return (
        <div id={styles.CartView} >
            <h2>Carrinho</h2>
            <div className={styles.container} >
                {

                    cartItems.length > 0 ?

                        cartItems?.map((cartItem) => <div key={cartItem.id} className={styles.cartItem}>
                            <div className={styles.main} >
                                <div className={styles.cartItemContent}>
                                    <Image src={cartItem.thumbnailUrl} className={styles.img}></Image>
                                    <span>
                                        {cartItem.name}
                                    </span>
                                    <b>
                                        {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(cartItem.totalPrice * cartItem.quantity)}
                                    </b>
                                </div>
                                <div className={styles.quantityContainer}>
                                    <div onClick={() => onPressRemoveHandle(cartItem)} className={styles.buttonQuantity}><Icon>remove</Icon></div>
                                    <div className={styles.valueQuantity}>{cartItem.quantity}</div>
                                    <div onClick={() => onPressAddHandle(cartItem)} className={styles.buttonQuantity}><Icon>add</Icon></div>
                                </div>
                            </div>
                            {(cartItem.description || !!cartItem.orderComposition?.length) && <div className={styles.accordeon}>
                                <div className={styles.description} >
                                    {cartItem.description}
                                </div>
                                <div className={styles.compositions} >
                                    {cartItem.orderComposition?.map((composition) => (
                                        <div className={styles.composition} >
                                            <label>{composition.description}</label>
                                            {composition.compositionItems.map(
                                                item => (
                                                    <span>{item.quantity} x {item.description} {item.totalPrice === 0 ? '' : new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.totalPrice)}</span>
                                                )
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </div>}
                        </div>)
                        :
                        <div className={styles.cartEmpty}>
                            <Empty />
                        </div>
                }
            </div >
            <div className={disabledSendButton ? styles.cartResumoDisabled : styles.cartResumo}>
                <div onClick={onClearHandle} className={styles.clearCartButton}>
                    <Icon>close</Icon><span>Esvaziar carrinho</span>
                </div>
                <div className={styles.resume}>
                    <span>Total de Itens: {totals.totalQuantity}</span>
                    <b>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totals.totalPrice)}</b>
                </div>
                <div className={styles.sendButton} onClick={() => !disabledSendButton && onClickSendButton?.(cartItems)}>
                    ENVIAR
                </div>
            </div>
        </div >
    )
}
export default CartView