import { v4 } from 'uuid';
import { IOrderItem } from '../../../../cart/domain/models/IOrderItem';
import {
  ConsumeOrderRequest,
  ConsumptionItemAdditionalRequest,
  ConsumptionItemObservationRequest,
  ConsumptionItemRequest,
  OrderRequest,
} from '../../../domain/dtos/ConsumeOrderRequest';

const ParseCartOrderItemsToConsumeOrderRequest = (
  localId: string,
  orderItems: IOrderItem[],
  accountId: string,
  orderPadId: string,
  equipamentId: string,
  orderNumber: string,
  orderId: string,
  operatorId?: string,
) => {
  const orderItemConsume: ConsumptionItemRequest[] = orderItems.map(orderItem => {
    const consumptionItemAdditionalRequest: ConsumptionItemAdditionalRequest[] =
      orderItem.orderComposition
        ?.flatMap(item => item.compositionItems)
        .filter(item => !!item.productId)
        .map(compositionItem => {
          return {
            selectedQuantity: compositionItem.quantity,
            productId: compositionItem.productId ?? '',
            unitValue: compositionItem.price,
          };
        }) ?? [];

    let consumptionItemObservationRequest: ConsumptionItemObservationRequest[] = [];
    orderItem.orderComposition?.forEach(itemComposition => {
      itemComposition.compositionItems.forEach(option => {
        if (!option.productId && option.quantity > 0) {
          consumptionItemObservationRequest.push({
            id: option.id, //uuid
            question: itemComposition.description,
            answer: option.description,
          });
        }
      });
    });

    const ticketsIds = Array.from(Array(orderItem.quantity).keys()).map(() => v4());

    return {
      // destinyId: orderPadId,
      productId: orderItem.productId, //uuid
      unitValue: orderItem.totalPrice,
      discountValue: 0,
      productName: orderItem.name,
      quantity: orderItem.quantity,
      additionals: consumptionItemAdditionalRequest,
      observations: consumptionItemObservationRequest,
      annotation: '',
      ticketIds: ticketsIds, //uuid
    };
  });

  const newOrderConsume: OrderRequest = {
    orderId, //uuid
    destinyId: orderPadId, //uuid
    orderNumber: orderNumber,
    createdAt: new Date().toISOString(),
    operatorId,
    items: orderItemConsume,
  };

  const requestConsume: ConsumeOrderRequest = {
    localId,
    accountId, //uuid
    equipamentId, //uuid
    order: newOrderConsume,
    operatorId
  };

  return requestConsume;
};
export default ParseCartOrderItemsToConsumeOrderRequest;
