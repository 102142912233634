import { IOrderComposition } from '../../domain/models/IOrderComposition';
import { v4 } from 'uuid';
import { ICartItem } from '../../domain/models/ICart';
import { IProductWithComposition as IProductWithCompositionCart } from '../../domain/models/IProductWithCompositionCart';

export const AddCartItemByProductUseCase = (
  prevCartItems: ICartItem[],
  product: IProductWithCompositionCart,
  quantity: number,
  orderCompositions?: IOrderComposition[],
): ICartItem[] => {
  const compareComposition = (composition?: IOrderComposition[], compositionToCopare?: IOrderComposition[]) => {
    try {
      const compositionString = JSON.stringify(composition);
      const compositionToCopareString = JSON.stringify(compositionToCopare);
      return compositionString === compositionToCopareString;
    } catch (error) {
      return false;
    }
  };

  const calcTotalPriceItem = (_product: IProductWithCompositionCart, _orderComposition?: IOrderComposition[]) => {
    const totalComposition =
      _orderComposition
        ?.flatMap(composititon => composititon.compositionItems.map(item => item.totalPrice))
        .reduce((previous, current) => previous + current, 0) ?? 0;
    return product.price + totalComposition;
  };

  const productToCartItem = (
    _product: IProductWithCompositionCart,
    _quantity: number,
    _orderCompositions?: IOrderComposition[],
  ) => {
    const newCartItem: ICartItem = {
      id: v4(),
      name: _product.name,
      category: _product.category,
      price: _product.price,
      description: _product.description,
      productPrice: _product.price,
      productId: _product.id,
      productType: _product.type,
      quantity: _quantity,
      imageUrl: _product.imageUrl,
      thumbnailUrl: _product.thumbnailUrl,
      barcode: _product.barcode,
      orderComposition: _orderCompositions,
      printerName: _product.printerName,
      totalPrice: calcTotalPriceItem(product, _orderCompositions),
      product: _product,
    };
    return newCartItem;
  };

  const addQuantityInExitCartItem = (_prevCartItems: ICartItem[], _id: string, _quantity: number) => {
    const newCartItems = _prevCartItems.map(cartItem => {
      if (cartItem.id === _id) {
        return { ...cartItem, quantity: cartItem.quantity + _quantity };
      } else {
        return cartItem;
      }
    });
    return newCartItems;
  };

  const addNewCartItem = (
    _prevCartItems: ICartItem[],
    _product: IProductWithCompositionCart,
    _quantity: number,
    _orderComposition?: IOrderComposition[],
  ) => {
    const newCartItems = [..._prevCartItems, productToCartItem(_product, _quantity, _orderComposition)];
    return newCartItems;
  };

  const existProductInCart = (
    _prevCartItems: ICartItem[],
    _product: IProductWithCompositionCart,
    _quantity: number,
    _orderCompositions?: IOrderComposition[],
  ) => {
    const existCartItem = _prevCartItems.find(
      cartItem =>
        cartItem.productId === _product.id && compareComposition(cartItem.orderComposition, _orderCompositions),
    );
    return existCartItem;
  };

  const addCartItem = (
    _prevCartItems: ICartItem[],
    _product: IProductWithCompositionCart,
    _quantity: number,
    _orderCompositions?: IOrderComposition[],
  ) => {
    const existCartItem = existProductInCart(_prevCartItems, _product, _quantity, _orderCompositions);
    const dontHaveComposition = !_orderCompositions?.length;

    if (existCartItem && dontHaveComposition) {
      const newCartItems = addQuantityInExitCartItem(_prevCartItems, existCartItem.id, _quantity);
      return newCartItems;
    } else {
      const newCartItems = addNewCartItem(_prevCartItems, _product, _quantity, _orderCompositions);
      return newCartItems;
    }
  };

  const execute = () => {
    return addCartItem(prevCartItems, product, quantity, orderCompositions);
  };

  return execute();
};
