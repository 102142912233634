import React,{FC} from 'react'
import styles from './FocusAlert.module.scss'
export interface IFocusAlertProps{
    //propertys
}
const FocusAlert:FC<IFocusAlertProps> = () => {
    return (
        <div id={styles.FocusAlert} >
            <div className={styles.container} >
            </div>
        </div>
)}
export default FocusAlert