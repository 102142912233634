import React, { FC, useCallback } from 'react'
import styles from './NumeriKeyboard.module.scss'
import ClearIcon from '../../../../assets/icons/clear.svg'
export interface INumeriKeyboardProps {
    //propertys
    onPressKey?: (key: string) => void
    enterLabel?: string
}
const NumeriKeyboard: FC<INumeriKeyboardProps> = ({ onPressKey, enterLabel }) => {
    const onPressKeyHandle = useCallback(
        (value: string) => {
            onPressKey?.(value);
        },
        [onPressKey],
    )

    return (
        <div id={styles.NumeriKeyboard} >
            <div className={styles.container} >
                <div className={styles.line}>
                    <div onClick={() => onPressKeyHandle('1')} className={styles.button}><p className={styles.number}>1</p></div>
                    <div onClick={() => onPressKeyHandle('2')} className={styles.button}><p className={styles.number}>2</p></div>
                    <div onClick={() => onPressKeyHandle('3')} className={styles.button}><p className={styles.number}>3</p></div>
                </div>
                <div className={styles.line}>
                    <div onClick={() => onPressKeyHandle('4')} className={styles.button}><p className={styles.number}>4</p></div>
                    <div onClick={() => onPressKeyHandle('5')} className={styles.button}><p className={styles.number}>5</p></div>
                    <div onClick={() => onPressKeyHandle('6')} className={styles.button}><p className={styles.number}>6</p></div>
                </div>
                <div className={styles.line}>
                    <div onClick={() => onPressKeyHandle('7')} className={styles.button}><p className={styles.number}>7</p></div>
                    <div onClick={() => onPressKeyHandle('8')} className={styles.button}><p className={styles.number}>8</p></div>
                    <div onClick={() => onPressKeyHandle('9')} className={styles.button}><p className={styles.number}>9</p></div>
                </div>
                <div className={styles.line}>
                    <div onClick={() => onPressKeyHandle('Apagar')} className={styles.buttonClear}><img src={ClearIcon} alt="limpar campo" /></div>
                    <div onClick={() => onPressKeyHandle('0')} className={styles.button}><p className={styles.number}>0</p></div>
                    <div onClick={() => onPressKeyHandle('Enter')} className={styles.buttonEnter}><p className={styles.enter}>{enterLabel}</p></div>
                </div>
            </div>
        </div>
    )
}
export default NumeriKeyboard