import {PrintType} from '../domain/enums/PrintType';
import {SystemType} from '../domain/enums/SystemType';

export enum OrderPadType {
  SHARED = 1,
  PERSONAL = 2,
}

export interface IDevice {
  id: string;
  generateEletronicInvoice: boolean;
  mustPrintEletronicInvoice: boolean;
  // hasSplitInvoice: boolean;
  barCodeMode?: boolean;
  enableBarCode?: boolean;
  localName: string;
  token: string;
  localId: string;
  printType: PrintType;
  addressLocalServer?: string;
  printCategoryInProductName: boolean;
  wallpaperImageUrl: string;
  customerField: string[];
  customerFieldEnable: boolean;
  customerFieldDisabled?: boolean;
  enableInstallment: boolean;
  paymentVoucher: boolean;
  paymentDebit: boolean;
  paymentCredit: boolean;
  deviceName: string;
  serviceRate: boolean;
  serviceRatePercent: number;
  orderPad: {id: string; number: string}[];
  configSymstem: {
    systemType: SystemType;
    printType: PrintType;
    enableRemotePrint?: boolean;
  }[];
  messageOnPayment: string;
  printReceipt: boolean;
  newPrinter: boolean;
  weighingMachineMode: boolean;
  paymentCashless: boolean;
  enableSchedule?: boolean;
  // onlyNextTime?: boolean;
  // enableCpfRegister?: boolean;
  // hideZeroPrice?: boolean;
  orderToGo: boolean;
  orderPadType: OrderPadType;
  localServerAddress: string;
  fromStorage?: boolean;
}
