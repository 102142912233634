import { CircularProgress } from '@mui/material';
import React, { createContext, FC, useCallback, useContext, useEffect, useState } from 'react';
import { useRef } from 'react';
import { v4 } from 'uuid';
import Toast, { ToastRef, typeToast } from '../components/Toast';
import styles from './UiContext.module.scss'
import 'react-toastify/dist/ReactToastify.css';
const __DEV__ = true;


interface IUiContext {
  showLoading: (id?: string, message?: string) => string;
  hideLoading: (id: string) => void;
  toast: (_message: string, _type?: typeToast, _icon?: string, _time?: number) => void;
  fullScreenToast: (_message: string, _type?: typeToast, _icon?: string, _time?: number) => void;
}

const UiContext = createContext<IUiContext>({} as IUiContext);

interface ILoadItem {
  id?: string;
  message?: string;
}

export const UiProvider: FC<{ children: React.ReactNode; }> = ({ children }) => {
  const [loadCount, setLoadCount] = useState<ILoadItem[]>([
    // {id: '123', message: 'Testando Loading'},
  ]);

  const toastRef = useRef<ToastRef>(null);

  const showLoading = useCallback((id: string = v4(), message?: string) => {
    __DEV__ && console.log('show loading: ' + id + ' ' + message);
    setLoadCount(prev => [...prev, { id, message }]);
    return id;
  }, []);
  const hideLoading = useCallback((id: string) => {
    setLoadCount(prev => prev.filter(item => item.id !== id));
    __DEV__ && console.log('hide loading: ' + id);
  }, []);

  const toast = useCallback((_message: string, _type?: typeToast, _icon?: string, _time?: number) => {
    toastRef.current?.show(_message, _type, _icon, _time);
  }, []);
  const fullScreenToast = useCallback((_message: string, _type?: typeToast, _icon?: string, _time?: number) => {
    toastRef.current?.showFullScreen(_message, _type, _icon, _time);
  }, []);

  useEffect(() => {
    __DEV__ && console.table(loadCount);
  }, [loadCount]);

  return (
    <UiContext.Provider
      value={{
        showLoading,
        hideLoading,
        toast,
        fullScreenToast
      }}>
      {children}
      {loadCount.length > 0 && (
        <div className={styles.backdrop}>
          <CircularProgress></CircularProgress>
          <div>{loadCount[0].message}</div>
          {/* {loadCount.map((item, index) => (
            <Text key={index} style={styles.textLoaging}>
              {item.message}
            </Text>
          ))} */}
        </div>
      )}
      <div>
        <Toast ref={toastRef} />
      </div>
    </UiContext.Provider>
  );
};



export const useUi = () => {
  const context = useContext(UiContext);
  return context;
};
