import localforage from 'localforage';
import { IDeviceRepository } from '../../../modules/device/domain/interfaces/IDeviceRepository';
import { IDevice } from '../../../modules/device/interface/Device';

const DeviceLocalStorage = (): IDeviceRepository => {
  const key = '@Device';
  const getDevice = async (): Promise<IDevice | null> => {
    const response = await localforage.getItem<IDevice>(key);
    return response ? { ...response, fromStorage: true } : null;
  };
  const setDevice = async (device: IDevice): Promise<void> => {
    await localforage.setItem(key, device);
    return;
  };
  return {
    getDevice,
    setDevice,
  };
};
export default DeviceLocalStorage;
