import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useCart } from '../../../../modules/cart/presentation/contexts/CartContext'
import { useCatalog } from '../../../../modules/catalog/context/CatalogContext'
import { IProduct } from '../../../../modules/catalog/domain/models/IProduct'
import { IProductCategory } from '../../../../modules/catalog/domain/models/IProductCategory'
import { IStore } from '../../../../modules/catalog/domain/models/IStore'
import Image from '../../../../components/ui/image/Image'
import styles from './CatalogList.module.scss'
import { Dialog, Drawer, Icon, IconButton, Modal, TextField } from '@mui/material'
import { v4 } from 'uuid'
import ProductDetail from '../productDetail/ProductDetail'
import { IOrderComposition } from '../../../../modules/cart/domain/models/IOrderComposition'
import SearchInput from '../../../../components/searchInput/SearchInput'

export interface ICatalogListProps {

}
const CatalogList: FC<ICatalogListProps> = () => {

    const { catalog } = useCatalog();
    const { addProductCart } = useCart()
    const [selectedStore, setSelectedStore] = useState<IStore>();
    const [selectedCategory, setselectedCategory] = useState<IProductCategory>();
    const [featured, setFeatured] = useState<IProductCategory>();

    const [openProduct, setOpenProduct] = useState<boolean>(false)
    const [selectedProduct, setSelectedProduct] = useState<IProduct>()

    const [filter, setFilter] = useState<string>("")

    useEffect(() => {
        if (catalog?.stores.length) {
            setSelectedStore(catalog.stores[0])
        }
    }, [catalog]);

    useEffect(() => {
        if (selectedStore?.seccionedProduct.length) {
            setselectedCategory(selectedStore.seccionedProduct[0])
        }
    }, [selectedStore]);

    useEffect(() => {
        const productsFeatured = selectedStore?.seccionedProduct.flatMap(category => category.products).filter((product) => product.isFeatured);
        if (productsFeatured?.length) {

            const _featured: IProductCategory = {
                id: v4(),
                title: "Mais Vendidos",
                imageUrl: "",
                products: productsFeatured ?? [],
            }
            setFeatured(_featured)
        }

    }, [selectedStore])



    const onClickStoreHandle = useCallback((store: IStore) => {
        setSelectedStore(store)
    }, [])

    const onClickCategoryHandle = useCallback((category: IProductCategory) => {
        setselectedCategory(category)
    }, [])

    const onClickProduct = useCallback((product: IProduct) => {
        if (product.compositions?.length) {
            setOpenProduct(true);
            setSelectedProduct(product)
        } else {
            addProductCart(product, 1)
        }
    }, [addProductCart])

    const onClickAddProduct = useCallback((product: IProduct, quantity: number = 1, composition?: IOrderComposition[]) => {
        addProductCart(product, 1, composition);
        setOpenProduct(false);
    }, [addProductCart]);


    const filterProducts = useMemo(() => {
        if (filter === "") return selectedCategory?.products
        if (selectedCategory?.products) {
            return selectedCategory?.products.filter(product => product.name.toLowerCase().includes(filter.toLowerCase()))
        }
        return []
    }, [filter, selectedCategory?.products])

    const filterFeatured = useMemo(() => {
        if (filter === "") return featured?.products
        if (featured?.products) {
            return featured?.products.filter(product => product.name.toLowerCase().includes(filter.toLowerCase()))
        }
        return []
    }, [filter, featured?.products])





    return (
        <div id={styles.CatalogList} >

            {catalog?.stores && catalog?.stores.length > 1 && < div className={styles.storeContainer} >
                {catalog?.stores.map(store => <div onClick={() => onClickStoreHandle(store)} className={styles.storeItem}>{store.name}</div>)}
            </div>}
            <div className={styles.categoryContainer} >
                <SearchInput
                    reset={() => setFilter("")}
                    onSubmit={(value) => setFilter(value)}
                />
                {featured && <div onClick={() => onClickCategoryHandle(featured)} className={selectedCategory?.id === featured.id ? styles.categoryItemSelected : styles.categoryItem}><Icon style={{ color: "gold" }}>star</Icon>{featured.title}</div>}
                {selectedStore?.seccionedProduct.map((category) => <div onClick={() => onClickCategoryHandle(category)} className={selectedCategory?.id === category.id ? styles.categoryItemSelected : styles.categoryItem}>{category.title}</div>)}
            </div>
            <div className={styles.productsContainer} >
                {
                    filterProducts?.map((product) => <div key={product.id} onClick={() => onClickProduct(product)} className={styles.productItem}>
                        <Image className={styles.img} width={90} height={90} src={product.thumbnailUrl}></Image>
                        <span>{product.name}</span>
                        <b>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(product.price)}</b>
                    </div>)
                }
            </div>
            {/* <Drawer open={!!openProduct} anchor={'right'} onClose={() => setOpenProduct(undefined)}> */}
            <Dialog open={!!openProduct} onClose={() => setOpenProduct(false)}>
                {selectedProduct &&
                    <ProductDetail
                        hideImage={true}
                        product={selectedProduct}
                        onClickAddProduct={onClickAddProduct}
                    />
                }
            </Dialog>
            {/* </Drawer> */}
        </div >
    )
}
export default CatalogList