
import React, { FC, PropsWithChildren, useCallback, useEffect, useRef, useState } from 'react';
import { Transition } from 'react-transition-group';
import { Icon, IconButton } from '@mui/material';

import styles from './Keyboard.module.scss';
import { log } from 'console';

const duration = 300;

export interface IKeyboardModalProps extends PropsWithChildren {
    inputRef?: React.RefObject<HTMLInputElement>;
    autoFocus?: boolean;
}

const KeyboardModal: FC<IKeyboardModalProps> = ({ children, inputRef, autoFocus = true }) => {
    const divRef = useRef<HTMLDivElement>(null);
    const isInputFocused = useRef<boolean>();
    const isKeyboardFocused = useRef<boolean>();



    const [open, setOpen] = React.useState(false);


    // useEffect(() => {
    //     console.log('inputRef?.current?.addEventListener', inputRef);
    //     inputRef?.current?.addEventListener('focus', () => {
    //         setOpen(true);
    //     })
    //     return () => {
    //         inputRef?.current?.removeEventListener('focus', () => {
    //             setOpen(true);
    //         })
    //     }
    // }, [inputRef])

    useEffect(() => {
        if (!inputRef) {
            setOpen(false)
        }
    }, [inputRef])


    const onClose = useCallback(() => {
        setOpen(false);
    }, [])

    useEffect(() => {
        const handleFocus = () => {
            console.log('handleFocus');
            isInputFocused.current = true;
            setOpen(true);
        };

        const handleBlur = () => {
            console.log('handleBlur');
            isInputFocused.current = false;
            if (!isKeyboardFocused.current) {
                setOpen(false);
            }
            // setOpen(false);
        };

        const inputElement = inputRef?.current;
        if (inputElement) {

            if (autoFocus) {
                setOpen(true);
                isInputFocused.current = true;
                inputElement.focus();
            }
            inputElement.addEventListener('focus', handleFocus);
            inputElement.addEventListener('blur', handleBlur);
        }

        return () => {
            if (inputElement) {
                handleBlur();
                // inputElement.removeEventListener('focus', handleFocus);
                // inputElement.removeEventListener('blur', handleBlur);
            }
        };
    }, [autoFocus, inputRef]);

    const transitionStyles = {
        entering: { transform: 'translateY(0)' },
        entered: { transform: 'translateY(0)' },
        exiting: { transform: 'translateY(130%)' },
        exited: { transform: 'translateY(130%)' },
    };

    const handleClickOutside = useCallback((event: MouseEvent) => {
        if (divRef.current && !divRef.current.contains(event.target as Node)) {
            isKeyboardFocused.current = false;
            // console.log('click outside' + isInputFocused.current);
            if (!(isInputFocused.current)) {
                setOpen(false);
            }
        } else {
            isKeyboardFocused.current = true;
            // console.log('click inside');
        }
    }, []);


    // useEffect(() => {
    //     if (!isInputFocused.current && !isKeyboardFocused.current) {
    //         setOpen(false);
    //         isInputFocused.current = (false);
    //     }
    // }, [onClose])

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [handleClickOutside]);

    return (
        <Transition nodeRef={divRef} in={open} timeout={duration}>
            {state => (
                <div
                    ref={divRef}
                    className={`${styles.float} ${isInputFocused.current ? styles.inputFocused : ''}`}
                    style={{
                        ...transitionStyles[state]
                    }}
                >
                    <IconButton className={styles.closeButton} onClick={onClose}>
                        <Icon>clear</Icon>
                    </IconButton>
                    {children}
                </div>
            )}
        </Transition>
    );
};

export default KeyboardModal;



