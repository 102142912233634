import React, { FC, useCallback, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuthentication } from '../../modules/authentication/presentation/contexts/AuthenticationContext';
import UseSharedOrderPad from '../../modules/consume/presentation/UseSharedOrderPad';
import { useDevice } from '../../modules/device/presentation/context/DeviceContext';
import UseOperator from '../../modules/operator/presentation/UseOperator'
import { SubmitReturnOperatorAndOrdePageFormValue } from './components/operatorAndOrderPadForm/OperatorAndOrderPadForm';
const UseOperatorPage = () => {
    const { getOperators, operators, getLocalOperators } = UseOperator();
    const { getSharedOrderPad, sharedOrderPadList, getLocalSharedOrderPad } = UseSharedOrderPad();
    const navigate = useNavigate()
    const { firstOpen } = useAuthentication();


    useEffect(() => {
        if (firstOpen) {
            navigate("/firstOpen")
        }
    }, [firstOpen, navigate])


    const onSubmitHandle = useCallback((value: SubmitReturnOperatorAndOrdePageFormValue) => {
        console.log(value);
        navigate(`/catalog/${value.operator.id}/${value.orderPadTag}/${value.sharedOrderPadNumber}`, { replace: true })
    }, [navigate])


    useEffect(() => {
        getLocalOperators();
        getOperators();

        getSharedOrderPad();
        getLocalSharedOrderPad();

        return () => {

        }
    }, [getLocalOperators, getLocalSharedOrderPad, getOperators, getSharedOrderPad])


    return ({ operators, sharedOrderPadList, onSubmitHandle })
}

export default UseOperatorPage